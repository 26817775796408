import { Auth } from 'aws-amplify';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import store, { VuexModuleNamespaces, DataStore } from '@/store';
import { getUser } from '@/service/user';
import { APP_CONFIGS } from '@/main';

export function getUserThumbnail(userId: string): string {
  return APP_CONFIGS.api.assetHost + "/" + userId;
}

export async function getUserAttributes(): Promise<CognitoUserAttribute[]> {
    // Lookup user
    const user = await Auth.currentAuthenticatedUser();
    store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUser.name}`, user);

    return new Promise((resolve, reject) => {
      user.getUserAttributes((err: Error, attributes: CognitoUserAttribute[])  => {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });
    });
}

export async function updateUser(): Promise<void> {
    try {
      // Lookup user
      const user = await Auth.currentAuthenticatedUser();
      store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUser.name}`, user);

      // Get attributes
      const attributes = await getUserAttributes();

      // Update email
      attributes.forEach(attribute => {
        if (attribute.Name === "email") {
          store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserEmail.name}`, attribute.Value);
        }
      });
      
      // Update user ID
      attributes.forEach(attribute => {
        if (attribute.Name === "sub") {
          store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserId.name}`, attribute.Value);
        }
      });

      // Update user name
      const userId = store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUserId.name}`]
      const username = (await getUser(userId)).data

      // Store User info
      if (username) {
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUsername.name}`, username.username);
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserThumbnail.name}`, username.userThumbnail);
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserNotifications.name}`, username.contact);
      }
    } catch(err) {
      store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUser.name}`, null);
      store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserId.name}`, null);
      store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUsername.name}`, null);
      store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserThumbnail.name}`, null);
      store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserNotifications.name}`, null);
    }
}

export function clearUserInfo(): void {
  store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUser.name}`, null);
  store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserId.name}`, null);
  store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUsername.name}`, null);
  store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserThumbnail.name}`, null);
  store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateUserNotifications.name}`, null);
}


export function showSignIn(): void {
  const event = new CustomEvent('xFrame:signIn', {
      cancelable: true
  });
  window.dispatchEvent(event);
  window.location.href = '/signIn'
}
