<template>
    <section id="slider" class="slider-element min-vh-100 include-header">
       <div class="slider-inner" style="background-image: url('one-page/images/pet.jpg');">
          <div class="vertical-middle px-3">
             <div class="container">
                <div class="row align-items-center justify-content-start text-start h-100">
                   <div class="col-md-9">
                      <div class="emphasis-title dark mb-5" data-bs-theme="dark">
                         <h2 data-animate="fadeInUp faster" class="mb-0 fadeInUp faster animated" style="font-size: 70px; font-weight: 700; font-family: 'Playfair Display', serif !important; line-height: 1.2">
                            Need a Vet?
                            <br>
                            Search Rabbit Vets in Your Area
                         </h2>
                      </div>
                      <form
                        id="pet-search"
                        @submit.prevent="searchForVet()"
                        style="max-width: 700px;"
                      >
                        <div class="input-group input-group-lg mt-2 mb-4">
                            <input
                                class="form-control border-0"
                                type="search"
                                placeholder="Search Location..."
                                aria-label="Search"
                                id="search-vet-input"
                                v-model="vetLocationSearch"
                            >
                            <button
                                class="button button-large m-0 bg-color text-light h-op-08"
                                type="submit"
                                value="submit"
                                style="border-top-right-radius: 0.5rem; border-bottom-right-radius: 0.5rem;"
                            >Search</button>
                        </div>
                      </form>
                   </div>
                </div>
             </div>
          </div>
          <img src="one-page/images/page/pet/hero.svg" alt="Image" style="position: absolute; bottom: -1px;">
       </div>
    </section>
    <div id="vetResults"></div>
    <section id="content" class="page-section" v-if="showIntro">
       <div class="content-wrap py-0">
          <div id="section-about" class="text-center" data-onepage-settings="{&quot;offset&quot;:70,&quot;speed&quot;:1250,&quot;easing&quot;:&quot;easeInOutExpo&quot;}">
             <div class="container">
                <div class="row justify-content-center mb-5">
                   <h3 class="h1 fw-bold mb-3" style="max-width: 700px">
                    Locate the best exotic animal vets in your area
                   </h3>
                   <p class="lead mx-auto mb-5" style="max-width: 800px;">
                    Discover a vet directory tailored specifically for rabbits.
                    We consolidate various sources to help you find top-notch, specialized care for your furry friends.
                    Make sure to read our <a href="/vet/guidelines">Guidelines</a> and leave a review of your rabbit care provider.
                </p>
                    <p class="mb-5" style="font-size: 16px;">
                        <a href="/vet/about" class="more-link">
                            Learn more <i class="uil uil-angle-right-b"></i>
                        </a>
                    </p>
                </div>
             </div>
          </div>
          <div class="container">
             <div class="row justify-content-center mb-5">
                <div class="col-xl-6 col-lg-8 text-center">
                   <h3 class="h1 fw-bold mb-3">Community Reviews</h3>
                </div>
             </div>
             <div class="row justify-content-center">
                <div class="col-lg-8">
                   <div class="block-testimonials card py-4">
                      <div id="oc-testi" class="owl-carousel testimonials-carousel carousel-widget" data-margin="0" data-items="1">
                         <div class="oc-item">
                            <div class="row g-0 align-items-center">
                                <VetReviewPreview :review="getVetReview(0)" v-if="getVetReview(0).reviewId"/>
                                <LoadingVetReviewPreview  v-else/>
                            </div>
                         </div>
                         <div class="oc-item">
                            <div class="row g-0 align-items-center">
                                <VetReviewPreview :review="getVetReview(1)" v-if="getVetReview(1).reviewId"/>
                                <LoadingVetReviewPreview  v-else/>
                            </div>
                         </div>
                         <div class="oc-item">
                            <div class="row g-0 align-items-center">
                               <VetReviewPreview :review="getVetReview(2)" v-if="getVetReview(2).reviewId"/>
                               <LoadingVetReviewPreview  v-else/>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section class="page-section" v-if="!showIntro && vets.length > 0 && !isApiCallInProgress">
        <div class="content-wrap py-0">
            <div class=container>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>
                                <h3 class="h1 fw-bold mb-3 text-center">Vets Near {{ lastSearch }}</h3>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="vet in sortedVets" :key="vet.googlePlaceId" @click="onVetSearchResultClick(vet.googlePlaceId)">
                            <td class="table-row">
                                {{ vet.name }} <i class="fa-solid fa-up-right-from-square"></i>
                                <br>
                                <p>
                                    <small>
                                        {{ vet.address }}
                                    </small>
                                    <small v-if="vet.totalRatings > 0">
                                        <br>
                                        {{ Number(vet?.rating).toFixed(1) }} &nbsp;
                                        <StarRating :rating=vet.rating ></StarRating>
                                        &nbsp;({{ (vet.totalRatings) }} <span v-if="vet.totalRatings === 1">community review</span><span v-else>community reviews</span>)
                                    </small>
                                    <small>
                                        <br>
                                        {{ Number(vet?.googleRating).toFixed(1) }} &nbsp;
                                        <StarRating :rating=vet.googleRating ></StarRating>
                                        &nbsp;({{ (vet.googleTotalRatings) }} <span v-if="vet.googleTotalRatings === 1"><i class="fa-brands fa-google"></i> review</span><span v-else><i class="fa-brands fa-google"></i> reviews</span>)
                                    </small>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <section class="page-section" v-if="!showIntro && vets.length == 0 && !isApiCallInProgress">
        <div class="content-wrap py-0">
            <div class=container>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th style="border: 0 !important;">
                                <h3 class="h1 fw-bold mb-3 text-center">Vets Near {{ lastSearch }}</h3>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="border: 0 !important;">
                                    No results found.  Try searching a different location or <a href="/about#contact">Contact Us</a>.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <section class="page-section" v-if="isApiCallInProgress">
        <div class="content-wrap py-0">
            <div class=container>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th style="border: 0 !important;">
                                <h3 class="h1 fw-bold mb-3 text-center">Vets Near {{ lastSearch }}</h3>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="index in 5" :key="index">
                                <td class="table-row-no-hover">
                                    <LoadingVetDetails />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <div class="section bg-transparent my-0 py-0">
        <div class="container-fluid py-6">
            <div class="row justify-content-center mx-auto mw-md gx-5">
                <div class="col">
                    <p class="h6 mb-0">
                        Our database combines multiple sources to generate a list of vets near your area.  Still don't see your vet? 
                        Need to correct some bad information? Have questions about our database?
                        <a href="/about#contact">Contact Us</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
 </template>
  
<script lang="ts">
import LoadingBox from "@/components/loading/LoadingBox.vue";
import LoadingVetDetails from "@/components/loading/LoadingVetDetails.vue";
import { Vue, Options } from 'vue-class-component';
import { APP_CONFIGS } from '@/main';
import { Vet, VetReview } from "@/models/Vet";
import { getRecentVetReviews, searchVets } from "@/service/vet";
import StarRating from "@/components/misc/StarRating.vue";
import ProfileThumbnail from "@/components/user/ProfileThumbnail.vue";
import VetReviewPreview from "@/components/vet/VetReviewPreview.vue";
import LoadingVetReviewPreview from "@/components/loading/LoadingVetReviewPreview.vue";

// eslint-disable-next-line
declare var google: any;

@Options({
    components: {
        LoadingBox,
        LoadingVetDetails,
        StarRating,
        ProfileThumbnail,
        VetReviewPreview,
        LoadingVetReviewPreview
    }
})
export default class VetHomeView extends Vue {

    showIntro = true;
    isApiCallInProgress = false;
    isApiCallLoadVetsInProgress = false;

    vets: Vet[] = [];
    vetReviews: VetReview[] = [];

    vetLocationSearch = "";
    lastSearch = "";

    async mounted(): Promise<void> {
        this.vetReviews = await this.publicLoadVetReviews();

        // Init autocomplete
        const input = document.getElementById("search-vet-input") as HTMLInputElement;
        const options = {
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: false,
            types: ["(cities)"],
        };
        new google.maps.places.Autocomplete(input, options);

        // Check for vet query param
        if (this.$route.query.city) {
            this.vetLocationSearch = this.$route.query.city as string;
            this.vets = (await searchVets(this.$route.query.city as string)).data;
            document.getElementById('vetResults')?.scrollIntoView({ behavior: 'smooth' });
            this.lastSearch = this.vetLocationSearch;
            this.showIntro = false;
        }
    }

    public async publicLoadVetReviews(): Promise<VetReview[]> {
        try {
            this.isApiCallLoadVetsInProgress = true;
            return (await getRecentVetReviews()).data;
        } catch(err) {
            console.log(err);
        } finally {
            this.isApiCallLoadVetsInProgress = false;
        }
        return [];
    }

    public getVetReview(index: number): VetReview {
        if (index + 1 <= this.vetReviews.length) {
            return this.vetReviews[index];
        } else {
            return {
                "reviewId": "",
                "vetId": "",
                "content": "",
                "rating": 0,
                "userId": "",
                "user": {
                    "userId": "",
                    "username": "",
                    "created": 0,
                    "badges": []
                },
                "created": 0,
                "attachments": []
            }
        }
    }

    async searchForVet(): Promise<void> {
        this.showIntro = false;
        document.getElementById('vetResults')?.scrollIntoView({ behavior: 'smooth' });
        try {
            this.vets = [];
            this.isApiCallInProgress = true;
            const city = (document.getElementById("search-vet-input") as HTMLInputElement).value;
            this.vetLocationSearch = city;
            this.lastSearch = city;
            this.vets = (await searchVets(city)).data;
        } catch(err) {
            this.isApiCallInProgress = false;
        } finally {
            this.isApiCallInProgress = false;
        }
    }

    onVetSearchResultClick(vetId: string): void {
        this.$router.push({
            path: `/vet/${vetId}`,
            query: {
                "city": this.vetLocationSearch
            }
        });
    }

    get assetHost(): string {
        return APP_CONFIGS.api.assetHost;
    }

    get sortedVets(): Vet[] {
      return this.vets.sort(
         (a, b) => 
         ((a.googleTotalRatings + a.totalRatings * 1000) > (b.googleTotalRatings + b.totalRatings * 1000))
          ? -1 : 
          ((a.googleTotalRatings + a.totalRatings * 1000) < (b.googleTotalRatings + b.totalRatings * 1000)) ? 1 : 0
      );
    }

}
</script>

<style scoped>
.table-row {
   cursor: pointer;
}
.table-row:hover {
   background-color: #ebebeb !important;
}
/** New  */
.page-section {
    padding: 120px 0;
}
</style>