<template>
   <div id="content">
      <div class="content-wrap py-0">
         <div class="section my-0 text-pop-image"
            style="background-image: url('https://assets.blog.theoutbackbowl.com/Outback_Bowl_V1_217f8f3517.jpg');"
         >
            <div class="container">
               <div class="row justify-content-between align-items-center align-items-xl-end">
                  <div class="col-lg-6 col-xxl-5 text-center text-lg-start">
                     <h1 class="display-4 fw-bolder ls-n-1 text-pop-image-text">The Outback Bowl</h1>
                     <p class="lead fw-normal mb-5 text-pop-image-text"> 
                        August. It is at this time of each year where we, the Four Gentlemen of the Bloomin' Onion™, 
                        embark upon our 18-week journey to a destiny which only the Football Gods are privy. 
                        Heartbreak, humiliation, and several hundred dollars of financial loss will be the fate of one. 
                        But in front of which seat the waiter will drop the check - that is far from certain.
                     </p>
                  </div>
                  <div class="col-lg-6 col-xxl-5 text-center text-lg-start">
                     <div style="text-align: center;">
                        <video style="width: 100%; height: auto;" controls poster="https://assets.blog.theoutbackbowl.com/OBB_Full_picture_36d945d5f1.png">
                           <source src="https://assets.blog.theoutbackbowl.com/OBB_2024_4_1_ab213d4817.mp4" type="video/mp4">
                           Your browser does not support the video tag.
                        </video>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="section my-0">
            <div class="container">
               <div class="row g-0 align-items-md-stretch align-items-lg-center">
                  <div class="col-xl-7 col-md-6 img-overlap">
                     <img :src="appConfigs.assets.homeAboutUsImage" alt="..." class="h-100" style="object-fit: cover;">
                  </div>
                  <div class="col-xl-5 col-md-6 bg-white">
                     <div class="position-absolute start-0 top-0 w-100 h-100 bg-color bg-opacity-10"></div>
                     <p class="color op-06 text-uppercase ls-4 small mb-3">Know More About Us</p>
                     <h2 class="display-6">The Outback Bowl</h2>
                     <p class="color">
                        Whose strategy will succeed? Whose will fail? Will the islands be fare or their seas be treacherous? 
                        Is there safety in numbers, or will the road less traveled make all the difference? 
                        Will lightning strike twice, or on virgin wallets? What is The Outback Bowl? 
                        What the hell are you guys even talking about? Who made this website?
                     </p>
                     <a href="/about" class="button button-small border-color button-border  m-0 color h-bg-color h-text-light">About Us</a>
                  </div>
               </div>
            </div>
         </div>
         <div class="section my-0">
            <div class="d-flex align-items-center">
               <div class="container">
                  <div class="row justify-content-center mb-5">
                     <div class="col-xl-6 col-lg-8 text-center">
                        <h3 class="h1 fw-bold mb-3">Meet the Outbackers</h3>
                     </div>
                  </div>
                  <div class="row justify-content-center">
                     <div class="col-lg-6 mb-5">
                        <div class="row team team-list rounded-5 g-0 align-items-center rounded-4 overflow-hidden">
                           <div class="col-md-4 team-image col-sm-5 d-flex align-self-stretch">
                              <img src="https://assets.blog.theoutbackbowl.com/bryant_79a88bf4bc.jpg" style="object-fit: contain">
                           </div>
                           <div class="col-md-8 col-sm-7 p-4">
                              <div class="team-desc text-start">
                                 <div class="team-title pt-0 mb-3">
                                    <h4>Bryant Bost</h4>
                                 </div>
                                 <p class="mb-3">
									The most handsome of the Outbackers, Bryant largely employs a strategy of favoring the home team. Bryant's
									seasons are historically "feast or famine," as he is the only player with multiple first place and multiple last 
									place finishes. 
								</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-5">
                        <div class="row team team-list rounded-5 g-0 align-items-center rounded-4 overflow-hidden">
							<div class="col-md-4 team-image col-sm-5 d-flex align-self-stretch">
                              <img src="https://assets.blog.theoutbackbowl.com/tom_11e4339984.png" style="object-fit: contain">
                           </div>
                           <div class="col-md-8 col-sm-7 p-4">
                              <div class="team-desc text-start">
                                 <div class="team-title pt-0 mb-3">
                                    <h4>Tom Priester</h4>
                                 </div>
                                 <p class="mb-3">
									Tom is the only Outbacker to have never been presented the check, yet Tom is routinely responsible 
									for the lion's share of the bill at each Loser's Dinner. Tom's strategy is simple: pick on pure instinct. 
									He refers to this as the Pick the Winners strategy.
								</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-5">
                        <div class="row team team-list rounded-5 g-0 align-items-center rounded-4 overflow-hidden">
							<div class="col-md-4 team-image col-sm-5 d-flex align-self-stretch">
                              <img src="https://assets.blog.theoutbackbowl.com/grey_01ee95841c.jpg" style="object-fit: contain">
                           </div>
                           <div class="col-md-8 col-sm-7 p-4">
                              <div class="team-desc text-start">
                                 <div class="team-title pt-0 mb-3">
                                    <h4>Grey Gordon</h4>
                                 </div>
                                 <p class="mb-3">
									There's one thing that you can count on in The Outback Bowl, and that is Grey living and 
									dying over the outcome of each game.  Grey has sweated out (heavily) every Sunday since 2018. 
									Grey employes a varied strategy to make his picks.
								</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-5">
                        <div class="row team team-list rounded-5 g-0 align-items-center rounded-4 overflow-hidden">
							<div class="col-md-4 team-image col-sm-5 d-flex align-self-stretch">
                              <img src="https://assets.blog.theoutbackbowl.com/randy_4387f20133.jpg" style="object-fit: contain">
                           </div>
                           <div class="col-md-8 col-sm-7 p-4">
                              <div class="team-desc text-start">
                                 <div class="team-title pt-0 mb-3">
                                    <h4>Randy Short</h4>
                                 </div>
                                 <p class="mb-3">
									In his day job, Randy is a professional data scientist. This makes it all the more puzzling that 
									Randy's proprietary "model" to predict the outcome of each game has made him a 3-time loser. Known 
									for his contrarian picks, he is nicknamed the "Island Boi."
								</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="section bg-color bg-opacity-10 my-0">
            <div class="container py-5">
               <div class="row justify-content-center mb-4">
                  <div class="col-md-6 text-center">
                     <p class="color text-uppercase ls-3 small mb-3">Blog</p>
                     <h3 class="display-5 mb-5">Our Latest Posts</h3>
                  </div>
               </div>
               <div class="row col-mb-50 posts-md">
                  <div class="col-lg-4 entry mb-0" v-for="post in posts.slice(0, 3)" :key=post.id>
                     <ArticlePreview :post="post" />
                  </div>
               </div>
            </div>
         </div>
         <div class="section bg-transparent my-0 py-0">
            <div class="container-fluid">
               <div class="clear"></div>
               <div class="row flex-md-row-reverse align-items-md-center">
                  <div class="col-md-6 px-lg-0 min-vh-50 min-vh-lg-75 d-flex align-self-stretch"  :style="`background: url(${appConfigs.assets.homeSectionImages[1]}) no-repeat center center; background-size: cover;`">
                  </div>
                  <div class="col-md-6 p-5 p-lg-6">
                     <h3 class="fs-2 fw-medium mb-4 px-lg-5">Explore Our Community Forum</h3>
                     <p class="px-lg-5 mb-5">
                        Looking for more Outback content? Dive into our community forum, where experienced 
                        Outbackers and experts share insights, tips, and stories to help you ensure can accurately pick the winners. 
                        Join the conversation, ask questions, and connect with fellow Outbackers enthusiasts today.
                        <br>
                        <br>
                        <a href="/community" class="read-more ls-1 text-uppercase border-bottom border-color small h-color h-op-08 ">Read More</a>
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <div class="section bg-transparent my-0 py-0">
            <div class="container-fluid py-6">
               <div class="row justify-content-center mx-auto mw-md gx-5">
                  <div class="col">
                     <p class="h6 mb-0">
                        Content and advice shared on this platform regarding represent the opinions of our community members 
                        and should not be considered betting advice.  We are morons.  If you have inquiries, wish to rectify misinformation, or 
                        seek clarification about our community, feel free to <a href="/about#contact">Contact Us</a>.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Post } from '@/models/Cms';
import { loadNewContent } from '@/utils/cms';
import { convertTimeToDate } from '@/utils/time';
import { APP_CONFIGS } from '@/main';
import { FrameControl } from '@/models/FrameControl';
import ArticlePreview from "@/components/post/ArticlePreview.vue";


@Options({
    components: {
        ArticlePreview,
    }
})
export default class ObbHome extends Vue {

    posts: Post[] = [];

    public async mounted(): Promise<void> {
        this.posts = await loadNewContent();
    }

    public convertTimeToDate(dateString: string): string {
		return convertTimeToDate(dateString);
    }

	get appConfigs(): FrameControl {
		return APP_CONFIGS;
	}

}
</script>

<style scoped>
</style>