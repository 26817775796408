<template>
    <ul class="topic list-unstyled row mx-0 justify-content-between align-items-center">
        <li class="entry mb-0 col-sm-10">
            <h3 class="mb-0" style="word-wrap: break-word">
                <a class="link-dark" :href="'/community/posts/' + post.postId">
                    {{ post.title }}
                </a>
            </h3>
            <div class="entry-meta mt-1">
                <ul>
                    <li>
                        <a :href="'/community/posts/' + post.postId">
                            {{ timeSince(Number(post.created)) }} ago
                        </a>
                    </li>
                    <li>
                        <a :href="'/community/posts/' + post.postId">
                            {{ formatNumber(post.likes) }} <span v-if="post.likes === 1">Like</span><span v-else>Likes</span>
                        </a>
                    </li>
                    <li>
                        <a :href="'/community/posts/' + post.postId">
                            {{ formatNumber(post.views) }} <span v-if="post.views === 1">View</span><span v-else>Views</span>
                        </a>
                    </li>
                    <li>
                        <a :href="'/community/posts/' + post.postId">
                            {{ post.comments }} <span v-if="post.comments === 1">Reply</span><span v-else>Replies</span>
                        </a>
                    </li>
                    <li>
                        <a :href="'/community/posts?category=' + post.category" class="badge bg-secondary text-white">
                            {{ post.category }}
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="col-sm-2">
            <div class="bbp-author">
                <a :href="'/user/' + post.userId">
                    <img
                        alt="Image"
                        :src="post.user?.userThumbnail ? assetHost + '/' + post.user?.userThumbnail : '/img/profile_not_found.png'"
                        onerror="this.onerror=null; this.src='/img/profile_not_found.png'"
                    >
                </a>
            </div>
        </li>
    </ul>
</template>

<script lang="ts">
import { Post } from '@/models/Community';
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { timeSince } from '@/utils/time';
import { formatNumber } from '@/utils/numbers';
import { APP_CONFIGS } from '@/main';

@Options({
    props: {
        post: {
            required: true,
            type: Object as PropType<Post>
        },
    }
})
export default class PostOutline extends Vue {

    post!: Post;

    public formatNumber(num: number): string {
      return formatNumber(num);
    }

    public timeSince(epochMilliseconds: number): string {
      return timeSince(epochMilliseconds);
    }

    get assetHost(): string {
        return APP_CONFIGS.api.assetHost;
    }

}
</script>


<style scoped>

</style>
