<template>
    <ul class="topic list-unstyled row mx-0 justify-content-between align-items-center">
        <div class="skeleton">
            <div class="d-flex flex-row-reverse align-items-center">
                <div class="skeleton-avatar mb-0"></div>
                <div class="d-flex flex-column flex-fill">
                    <div class="skeleton-title mb-0 me-3 w-50"></div>
                    <div class="skeleton-title mb-0 me-5 mt-2"></div>
                </div>
            </div>
        </div>
    </ul>
 </template>
 
 <script lang="ts">
 import { Vue } from 'vue-class-component';
 
 export default class LoadingPostOutline extends Vue {
 
 }
 </script>
 
 <style scoped>

 </style>