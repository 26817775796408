<template>
     <footer id="footer" class="dark bg-black border-top-0">
        <div class="container py-4">
           <div class="footer-widgets-wrap">
              <div class="row col-mb-50">
                 <div class="col-md-4">
                    <div class="widget subscribe-widget">
                       <h3 class="text-transform-none ls-0 border-bottom d-inline-block mb-5">Subscribe to Our Newsletter</h3>
                       <p>Get Important News, Offers, and Inside Scoops Right to Your Inbox</p>
                       <div class="widget-subscribe-form-result"></div>
                       <form id="widget-subscribe-form" @submit.prevent="sendSubscribeMessage" class="mb-0">
                          <div class="input-group mx-auto">
                             <input
                              type="email"
                              id="widget-subscribe-form-email"
                              name="widget-subscribe-form-email"
                              class="form-control required email"
                              placeholder="Enter your Email"
                              v-model=subscribeEmail
                           >
                             <button class="btn border-start-0" type="submit">
                              <LoadingSpinner v-if="isApiCallInProgress" />
                              <span v-else><i class="bi-arrow-right h-op-08"></i></span>
                           </button>
                          </div>
                       </form>
                    </div>
                 </div>
                 <div class="col-lg-3 col-6 offset-md-2">
                    <div class="widget widget_links">
                       <h3 class="text-transform-none ls-0 border-bottom d-inline-block mb-5">Contact Us</h3>
                       <ul>
                          <li class="mb-2"><a class="ls-1 text-light" @click="flagContent('Home')" style="cursor: pointer">Report Content</a></li>
                          <li class="mb-2"><a class="ls-1 text-light" href="/about#contact">Message Us</a></li>
                          <li class="mb-2"><a class="ls-1 text-light" href="/signIn">Sign In/Register</a></li>
                       </ul>
                    </div>
                 </div>
                 <div class="col-lg-3 col-6">
                    <div class="widget widget_links">
                       <h3 class="text-transform-none ls-0 border-bottom d-inline-block mb-5">Follow Us</h3>
                       <ul>
                            <li class="mb-2" v-for="[key, value] in Object.entries(appConfigs.social)" :key="key" :href="value" target="_blank">
                                <a class="ls-1 text-light" target="_blank" :href="value">
                                    {{ key.charAt(0).toUpperCase() + key.slice(1) }}
                                </a>
                            </li>
                       </ul>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div id="copyrights" style="border-top: 1px solid rgba(255,255,255,.2)">
           <div class="container">
              <div class="row col-mb-30">
                 <div class="col-md-4 text-center text-md-start">
                    Copyrights &copy; 2024 All Rights Reserved
                 </div>
                 <div class="col-md-8 text-center text-md-end">
                    <div class="copyright-links">
                        <a href="/siteInfo/terms">
                            Terms & Conditions
                        </a> /
                        <a href="/siteInfo/privacyPolicy">
                            Privacy Policy
                        </a> /
                        <a href="/siteInfo/help">
                            Help
                        </a> /
                        <a href="/siteInfo/cookiePolicy">
                            Cookie Policy
                        </a> /
                        <a @click="$cookieConsent.showPreferences()" style="cursor: pointer">
                            Cookie Settings
                        </a>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </footer>
</template>

<script lang="ts">
import { APP_CONFIGS } from '@/main';
import { FrameControl } from '@/models/FrameControl';
import { createContact } from '@/service/common';
import store, { DataStore, VuexModuleNamespaces } from '@/store';
import { flagContent } from '@/utils/flag';
import { Options, Vue } from 'vue-class-component';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';

@Options({
    components: {
      LoadingSpinner
    }
})
export default class Footer extends Vue {

   isApiCallInProgress = false;

   subscribeEmail = "";

   public flagContent(content: string): void {
      flagContent(content);
   }

   public async sendSubscribeMessage(): Promise<void> {
      try {
         this.isApiCallInProgress = true;
         await createContact({
            email: this.subscribeEmail,
            content: "I want to subscribe to the mailing list "
         });
         window.location.reload();
      } catch(err) {
         store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
      } finally {
         this.isApiCallInProgress = false;
      }
   }

   get appConfigs(): FrameControl {
      return APP_CONFIGS;
   }

}
</script>


<style scoped>

</style>
