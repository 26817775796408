import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-07d5dfba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-4 d-flex flex-column text-center align-items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "text-dark multi-line-ellipsis m-0" }
const _hoisted_5 = { class: "text-muted mb-0 fw-normal" }
const _hoisted_6 = { class: "col-md-8 p-4 testi-content" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "text-dark multi-line-ellipsis m-0" }
const _hoisted_9 = { class: "m-0 pb-3 multi-line-ellipsis" }
const _hoisted_10 = { class: "text-larger mb-0 multi-line-ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StarRating = _resolveComponent("StarRating")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.review.user.userThumbnail ? _ctx.assetHost + '/' + _ctx.review.user.userThumbnail : '/img/profile_not_found.png',
        onerror: "this.onerror=null; this.src='/img/profile_not_found.png'",
        class: "rounded-circle mb-3 w-auto thumbnail-pic",
        width: "64",
        height: "64"
      }, null, 8, _hoisted_2),
      _createElementVNode("a", {
        href: '/vet/' + _ctx.review.vetId
      }, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.review.vetDetails?.name), 1)
      ], 8, _hoisted_3),
      _createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.timeSince(Number(_ctx.review.created))) + " ago ", 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("a", {
        href: '/vet/' + _ctx.review.vetId
      }, [
        _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.review.vetDetails?.name), 1)
      ], 8, _hoisted_7),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.review.vetDetails?.city), 1),
      _createElementVNode("p", null, [
        _createVNode(_component_StarRating, {
          rating: Number(_ctx.review.rating)
        }, null, 8, ["rating"])
      ]),
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.getPreviewString(_ctx.review.content)), 1)
    ])
  ], 64))
}