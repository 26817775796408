export function formatNumber(num: number): string {
    if (!num) {
        return "0";
    } else {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + "M";
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + "k";
        } else {
            return num.toString();
        }
    }
  }