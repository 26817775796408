<template>
    <div class="skeleton">
        <div class="skeleton-title w-50 mb-4"></div>
        <ul class="skeleton-para list-unstyled mb-0 w-50">
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
 </template>
 
 <script lang="ts">
 import { Vue } from 'vue-class-component';
 
 export default class LoadingVetDetails extends Vue {
 
 }
 </script>
 
 <style scoped>
 .placeholder {
     display: inline-block;
     width: 100px; 
     height: 16px; 
     background-color: #e0e0e0; 
     border-radius: 4px; 
     margin-bottom: 4px; 
 }
 </style>