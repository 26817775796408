
export function getPreviewString(content: string): string {
    const formattedString = formatHtmlString(content);
    const tempElement = document.createElement("div");
    tempElement.innerHTML = formattedString;
    return extractInnerTextWithLineBreaks(tempElement);
}

function formatHtmlString(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const body = doc.body;

    const elementsToBreak: Element[] = [];

    const childNodes = body.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
        const child = childNodes[i];
        if (child instanceof HTMLElement && (child.tagName === 'P' || child.tagName === 'UL')) {
        elementsToBreak.push(child);
        }
    }

    for (let i = 0; i < elementsToBreak.length; i++) {
        const element = elementsToBreak[i];
        const lineBreak = document.createElement('br');
        body.insertBefore(lineBreak.cloneNode(), element);
        body.insertBefore(lineBreak, element.nextSibling);
    }

    const serializedHTML = new XMLSerializer().serializeToString(body);
    return serializedHTML;
}

function extractInnerTextWithLineBreaks(element: HTMLElement): string {
    let result = '';
    function traverse(node: Node) {
        if (node.nodeType === Node.TEXT_NODE) {
            result += node.textContent + '\n';
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (let child = node.firstChild; child; child = child.nextSibling) {
                traverse(child);
            }
        }
    }
    traverse(element);
    return result.slice(0, -1);
}