import { FrameControl } from "@/models/FrameControl";
import { Auth } from "aws-amplify";
import axios, { AxiosResponse } from "axios";
import config from '../../configs/configs.json';
import {
  Post,
  PostList,
  PostLike,
  PostComment,
  PostCommentLike,
  LastStartKey,
  PostContent,
  CommentContent
} from "@/models/Community";


export const CONFIGS: FrameControl = config;
export const PUBLIC_HOST = CONFIGS.api.publicHost;
export const PRIVATE_HOST = CONFIGS.api.privateHost;


async function getAccessToken(): Promise<string> {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
}

export async function listTopPosts(
  category: string | undefined,
  search: string | undefined,
  offset: number,
  searchDays: number
): Promise<AxiosResponse<PostList>> {
  const postList = (await axios.get<PostList>(PUBLIC_HOST + "/community/post", {
    params: {
      "category": category,
      "search": search,
      "offset": offset,
      "searchDays": searchDays
    }
  }))
  return postList;
}

export async function listPosts(
  category: string | undefined,
  search: string | undefined,
  lastStartKey: LastStartKey | undefined | null): Promise<AxiosResponse<PostList>> {
    const postList = (await axios.get<PostList>(PUBLIC_HOST + "/community/post", {
      params: {
        "category": category,
        "postId": lastStartKey?.postId,
        "created": lastStartKey?.created,
        "dated": lastStartKey?.dated,
        "search": search
      }
    }))
    return postList;
}

export async function getPost(postId: string): Promise<AxiosResponse<Post>> {
    return axios.get<Post>(PUBLIC_HOST + `/community/post/${postId}`);
}

export async function deletePost(postId: string): Promise<AxiosResponse<Post>> {
  return axios.delete<Post>(PRIVATE_HOST + `/community/post/${postId}`, {
    headers: {
      Authorization: await getAccessToken()
    }
  });
}

export async function createPost(contents: PostContent): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/community/post`,
    contents,
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function editPost(postId: string, contents: PostContent): Promise<AxiosResponse<void>> {
  return axios.put<void>(PRIVATE_HOST + `/community/post/${postId}`,
    contents,
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function likePost(postId: string): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/community/post/${postId}/like`,
    {},
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function unlikePost(postId: string): Promise<AxiosResponse<void>> {
  return axios.delete<void>(PRIVATE_HOST + `/community/post/${postId}/like`, {
    headers: {
      Authorization: await getAccessToken()
    }
  });
}

export async function getPostLikes(postId: string): Promise<AxiosResponse<PostLike[]>> {
  return axios.get<PostLike[]>(PUBLIC_HOST + `/community/post/${postId}/like`);
}

export async function getPostComments(postId: string): Promise<AxiosResponse<PostComment[]>> {
  return axios.get<PostComment[]>(PUBLIC_HOST + `/community/post/${postId}/comment`);
}

export async function getPostCommentLikes(postId: string): Promise<AxiosResponse<PostCommentLike[]>> {
  return axios.get<PostCommentLike[]>(PUBLIC_HOST + `/community/post/${postId}/comment/like`);
}

export async function getComment(postId: string, commentId: string): Promise<AxiosResponse<PostComment>> {
  return axios.get<PostComment>(PUBLIC_HOST + `/community/post/${postId}/comment/${commentId}`);
}

export async function createComment(postId: string, content: CommentContent): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/community/post/${postId}/comment`,
  content,
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function editComment(postId: string, commentId: string, content: CommentContent): Promise<AxiosResponse<void>> {
  return axios.put<void>(PRIVATE_HOST + `/community/post/${postId}/comment/${commentId}`,
  content,
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function deleteComment(postId: string, commentId: string): Promise<AxiosResponse<void>> {
  return axios.delete<void>(PRIVATE_HOST + `/community/post/${postId}/comment/${commentId}`, {
    headers: {
      Authorization: await getAccessToken()
    }
  });
}

export async function likeComment(postId: string, commentId: string): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/community/post/${postId}/comment/${commentId}/like`,
    {},
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function unlikeComment(postId: string, commentId: string): Promise<AxiosResponse<void>> {
  return axios.delete<void>(PRIVATE_HOST + `/community/post/${postId}/comment/${commentId}/like`, {
    headers: {
      Authorization: await getAccessToken()
    }
  });
}