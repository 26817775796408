<template>
   <div class="mb-0 bg-transparent mt-5">
      <div class="container">
         <div class="row align-items-center col-mb-30">
            <div class="col-lg-4">
               <div class="hstack mb-3">
                  <span class="col-auto me-3 text-uppercase ls-3 text-smaller color">About Us</span>
                  <div class="border-top border-light w-100 border-color"></div>
               </div>
               <h2 class="display-2 font-secondary text-stretch text-uppercase fw-bolder ls-1">
                  {{ appConfigs.layout.about.bioHeader }}
               </h2>
               <p class="op-07 fw-light">
                  {{ appConfigs.layout.about.bio }}
               </p>
               <span v-for="[key, value] in Object.entries(appConfigs.social)" :key="key" :href="value">
                  <a :href="value" class="social-icon bg-light rounded-circle border-transparent si-small h-bg-youtube" v-if="key === 'youtube'">
                     <i class="fa-brands fa-youtube"></i>
                     <i class="fa-brands fa-youtube"></i>
                  </a>
                  <a :href="value" class="social-icon bg-light rounded-circle border-transparent si-small h-bg-instagram" v-if="key === 'instagram'">
                     <i class="fa-brands fa-instagram"></i>
                     <i class="fa-brands fa-instagram"></i>
                  </a>
                  <a :href="value" class="social-icon bg-light rounded-circle border-transparent si-small h-bg-amazon" v-if="key === 'amazon'">
                     <i class="fa-brands fa-amazon"></i>
                     <i class="fa-brands fa-amazon"></i>
                  </a>
                  <a :href="value" target="_blank" class="social-icon bg-light rounded-circle border-transparent si-small h-bg-tiktok" v-if="key === 'tiktok'">
                     <i class="fa-brands fa-tiktok"></i>
                     <i class="fa-brands fa-tiktok"></i>
                  </a>
               </span>
            </div>
            <div class="col-lg-8 d-flex justify-content-center">
               <img :src="appConfigs.assets.aboutImage" alt=".." class="rounded-6 fadeInUpSmall animated max-height-img" data-animate="fadeInUpSmall">
            </div>
         </div>
      </div>
   </div>
   <div class="section mb-0 bg-transparent mt-0">
      <h2 class="h1 fw-normal text-center mb-6">Frequently Asked Questions</h2>
      <div class="container">
         <div class="row align-items-center col-mb-30">
            <div v-for="faq in appConfigs.layout.about.faq" :key="faq.question">
               <div class="toggle">
                  <div class="toggle-header justify-content-between flex-row-reverse">
                     <div class="toggle-icon">
                        <i class="toggle-closed uil uil-plus"></i>
                        <i class="toggle-open uil uil-minus"></i>
                     </div>
                     <div class="toggle-title h5 mb-0 fw-medium">
                        {{ faq.question }}
                     </div>
                  </div>
                  <div class="toggle-content op-07 mt-2" style="display: none;">
                     {{ faq.answer }}
                  </div>
               </div>
               <div class="line border-color my-4 op-01"></div>
            </div>
         </div>
      </div>
   </div>
   <div class="section mb-0 bg-transparent mt-0">
      <h2 class="h1 fw-normal text-center mb-6">Support Us </h2>
      <div class="container">
         <div class="row align-items-center col-mb-30">
            <div class="op-07">
               Love what we do? Help us keep it running smoothly! 
               Buy us a coffee to support the hosting costs of our website. 
               Your contribution goes directly towards maintaining our platform and bringing you more of what you love. 
               Thank you for your support!
            </div>
            <div class="mt-4">
               <a :href="appConfigs.general.supportLink" target="_blank">
                  <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style="height: 60px !important;width: 217px !important;" >
               </a>
            </div>
         </div>
      </div>
   </div>
   <div class="section mb-0 bg-transparent mt-0" id="contact">
      <div class="text-center mb-5">
         <p class="color text-uppercase ls-3 small mb-3">Get in Touch</p>
         <h2 class="h1 fw-normal text-center mb-6">Contact Us</h2>
      </div>
      <div class="container">
         <div class="row align-items-center col-mb-30">
            <form @submit.prevent="sendContactEmail">
               <div class="row g-3">
                  <form class="row g-3 needs-validation" novalidate>
                     <div class="col-12">
                        <div class="form-floating">
                           <input
                              type="email"
                              class="required form-control rounded-0 text-center text-md-start"
                              id="email"
                              name="email"
                              placeholder="Email"
                              v-model=contactEmail
                              required
                              >
                           <label for="email">Email</label>
                           <div class="invalid-feedback">
                              Email required.
                           </div>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="form-floating">
                           <textarea
                              class="required form-control rounded-0 text-center text-md-start"
                              id="message"
                              name="message"
                              placeholder="Leave a message here"
                              style="height: 130px"
                              v-model="contactMessage"
                              required
                              >
                           </textarea>
                           <label for="message">Message</label>
                           <div class="invalid-feedback">
                              Message required.
                           </div>
                        </div>
                     </div>
                     <div>
                        <button class="button button-large m-0 bg-color text-light h-op-08" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">
                           <LoadingSpinner v-if="isApiCallInProgress" />
                           <span v-else>Send Message</span>
                        </button>
                     </div>
                  </form>
               </div>
            </form>
         </div>
      </div>
   </div>
</template>

<script lang="ts">
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import { APP_CONFIGS } from '@/main';
import { FrameControl } from '@/models/FrameControl';
import { createContact } from '@/service/common';
import store, { DataStore, VuexModuleNamespaces } from '@/store';
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
      LoadingSpinner
    }
})
export default class AboutView extends Vue {

   isApiCallInProgress = false;

   contactEmail = "";
   contactMessage = "";

   public mounted(): void {
        const forms = document.querySelectorAll('.needs-validation');

        Array.prototype.slice.call(forms).forEach((form: HTMLFormElement) => {
            form.addEventListener('submit', async (event: Event) => {
                event.preventDefault();
                event.stopPropagation();
                if (form.checkValidity()) {
                    await this.sendContactEmail();
                }
            form.classList.add('was-validated');
            }, false);
        });
    }

   public async sendContactEmail(): Promise<void> {
      try {
         this.isApiCallInProgress = true;

         await createContact({
            email: this.contactEmail,
            content: this.contactMessage
         })

         window.location.href = '/about';
      } catch(err) {
         store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
      } finally {
         this.isApiCallInProgress = false;
      }
   }

   get appConfigs(): FrameControl {
      return APP_CONFIGS;
   }

}
</script>

<style>
.was-validated .form-control:valid, .form-control.is-valid {
    border-color: black !important;
    background-image: none;;
}
.max-height-img {
    max-height: 500px;
    object-fit: contain;
    max-width: 100%;
}
</style>
