import axios, { AxiosResponse } from "axios";
import config from '../../configs/configs.json';
import { FrameControl } from "@/models/FrameControl";
import { PostPresignedUrl } from "@/models/ImageAsset";
import { Report } from "@/models/Report";
import { Contact } from "@/models/Contact";

export const CONFIGS: FrameControl = config;
export const PUBLIC_HOST = CONFIGS.api.publicHost;
export const PRIVATE_HOST = CONFIGS.api.privateHost;

export async function getPresignedUrl(contentType: string): Promise<AxiosResponse<PostPresignedUrl>> {
  return axios.get<PostPresignedUrl>(PUBLIC_HOST + `/community/document`, {
      params: {
        contentType: contentType
      }
  });
}

export async function createReport(report: Report): Promise<AxiosResponse<void>> {
  return axios.post<void>(PUBLIC_HOST + `/report`,
    report
  );
}

export async function createContact(contact: Contact): Promise<AxiosResponse<void>> {
  return axios.post<void>(PUBLIC_HOST + `/report`,
    contact
  );
}

