<template>
   <div class="container">
      <div>
         <div class="row">
            <div v-if="showPostView">
               <section class="py-4 page-title-center">
                  <div class="container">
                     <div class="page-title-row">
                        <nav aria-label="breadcrumb">
                           <ol class="breadcrumb mt-4">
                              <li class="breadcrumb-item">
                                 <router-link class="alt-color" to="/community/posts">
                                    Community
                                 </router-link>
                              </li>
                              <li class="breadcrumb-item">
                                 <a class="alt-color" :href="'/community/posts?category=' + postDetails?.category">
                                 {{ postDetails?.category }}
                                 </a>
                              </li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </section>
               <section id="content">
                  <div class="container" v-if=isLoadPostApiCallInProgress>
                     <LoadingPost />
                  </div>
                  <div class="container">
                     <div class="topic-post p-4 p-md-5 border-top border-width-5 border-color">
                        <div v-if=!isLoadPostApiCallInProgress>
                           <div class="row justify-content-between align-items-center mb-4">
                              <div class="col-12">
                                 <h2 class="mb-0 fw-bold" style="word-wrap: break-word">
                                    {{ postDetails?.title }}
                                 </h2>
                              </div>
                           </div>
                           <div class="row g-0 justify-content-between align-items-center border-top border-bottom py-3 mb-4">
                              <div class="col-9">
                                 <div class="d-flex align-items-center">
                                    <img
                                       alt="Image"
                                       :src="postDetails?.user?.userThumbnail ? assetHost + '/' + postDetails?.user?.userThumbnail : '/img/profile_not_found.png'"
                                       onerror="this.onerror=null; this.src='/img/profile_not_found.png'"
                                       class="rounded-circle me-2"
                                       width="50"
                                       height="50"
                                       >
                                    <div>
                                       <h5 class="mb-0 h6 fw-semibold">
                                          <a class="text-dark" :href="'/user/' + postDetails?.userId">
                                          {{ postDetails?.user?.username ?? 'Unknown' }}
                                          </a>
                                       </h5>
                                       <small class="text-muted mb-0 fw-normal">
                                       {{ timeSince(Number(postDetails?.created)) }} ago
                                       <span v-if="postDetails?.edited">
                                       &nbsp;(Edited {{ timeSince(Number(postDetails?.edited)) }} ago)&nbsp;
                                       </span>
                                       </small>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-auto">
                                 <a href="#" id="editlink2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                 <i class="uil uil-ellipsis-h icon-2x alt-color"></i>
                                 </a>
                                 <div class="dropdown-menu dropdown-menu-links rounded shadow-sm dropdown-menu-end py-0 m-0" aria-labelledby="editlink2">
                                    <a
                                       class="dropdown-item"
                                       :href="'/community/submit?edit=' + postDetails?.postId"
                                       v-if="isOwnPost"
                                       >
                                    <i class="bi bi-pencil me-1"></i> Edit
                                    </a>
                                    <a
                                       class="dropdown-item"
                                       href="#"
                                       data-bs-toggle="modal"
                                       data-bs-target="#deletePostModal"
                                       v-if="isOwnPost"
                                       >
                                    <i class="bi bi-trash me-1"></i> Delete
                                    </a>
                                    <a
                                       class="dropdown-item"
                                       href="#"
                                       @click="flagContent('Post ' + postId)"
                                       >
                                    <i class="bi bi-flag me-1"></i> Flag
                                    </a>
                                 </div>
                              </div>
                           </div>
                           <div class="clear"></div>
                           <div class="rendered-html mb-4" style="word-wrap: break-word" v-html="postDetails?.content"></div>
                        </div>
                        <div class="row" :class="{ 'd-none': !postDetails?.attachments || isLoadPostApiCallInProgress }">
                           <div
                              class="col-lg-2 col-md-3 col-4 lightbox-preview-row"
                              v-for="index in [0, 1, 2, 3, 4]"
                              :key="index"
                              >
                              <a :href="assetHost + '/' + postDetails?.attachments[index]" data-lightbox="image">
                                 <img
                                    v-if=" postDetails?.attachments[index] !== undefined"
                                    :src="assetHost + '/' + postDetails?.attachments[index]" alt="Image"
                                    class="mb-1 rounded-1 lightbox-preview-image"
                                 >
                              </a>
                              <div class="d-flex align-items-center justify-content-between px-2">
                              </div>
                           </div>
                        </div>
                        <div class="lh-sm small mt-3 op-06">
                           {{ formatNumber(postDetails?.views) }} Views
                        </div>
                        <div>
                           <button
                              type="button"
                              class="button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2 me-2"
                              @click="likePost"
                              >
                           <i class="bi bi-hand-thumbs-up"></i>Like <span v-if="postLikes.length > 0">({{ postLikes.length }})</span>
                           </button>
                           <button
                              type="button"
                              class="button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2"
                              @click="toggleShowEditor(true)"
                              >
                           <i class="bi bi-reply"></i>Reply
                           </button>
                        </div>
                        <div v-if="showEditor" class="mb-5">
                           <form @submit.prevent="createComment()" class="my-4">
                              <div>
                                 <TextEditor ref="commentPostTextEditor" :enableImage="false" />
                                 <button
                                    type="submit"
                                    :disabled="isSubmitCommentApiCallInProgress"
                                    class="button button-small m-0 bg-color text-light h-op-08 me-2 mt-2"
                                    >
                                    <LoadingSpinner v-if="isSubmitCommentApiCallInProgress" />
                                    <span v-else>Submit</span>
                                 </button>
                                 <button
                                    class="button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2 me-2"
                                    @click="toggleShowEditor(false)"
                                    >
                                 Close
                                 </button>
                                 <div class="my-2 text-danger" v-if="isShowInvalidComment">
                                    Comment must be between 5 - 2000 characters. 
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </section>
               <section id="content">
                  <div class="mt-3">
                     <div class="container">
                        <div class="mb-3">
                           <h2 class="mb-0 fw-bold">
                              Comments <span v-if="!isLoadPostApiCallInProgress">({{ formatNumber(postComments.length) }})</span>
                           </h2>
                        </div>
                        <hr class="mb-4">
                        <div v-if="!isLoadPostApiCallInProgress">
                           <div v-for="comment in parentComments" :key="comment.commentId">
                              <Comment
                                 :postComments="postComments"
                                 :postCommentLikes="postCommentLikes"
                                 :postId="postId"
                                 :commentId="comment.commentId"
                                 :commentLevel="1"
                                 :isShowCommentsMode="isShowCommentsMode"
                                 />
                           </div>
                        </div>
                        <div v-if="isLoadPostApiCallInProgress">
                           <div v-for="index in [1, 2, 3]" :key="index">
                              <LoadingComment class="mb-3" />
                           </div>
                        </div>
                        <div
                           v-if="!isLoadPostApiCallInProgress && parentComments.length === 0" class="my-5 text-center"
                           >
                           <div class="feature-box fbox-effect flex-column">
                              <div class="fbox-content">
                                 <h3>No Comments</h3>
                                 <p>Be the first to share what you think</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section id="content">
                  <div class="content-wrap">
                     <div class="container mw-md mb-5" v-if="!isLoggedIn">
                        <div>
                           <div class="signup-box">
                              <h2>Create an account or sign in to comment</h2>
                              <p>You need to be a member in order to leave a comment</p>
                              <button class="signup-btn" onclick="window.location.href='/signIn';">
                              SIGN UP / LOGIN
                              </button>
                           </div>
                        </div>
                     </div>
                     <div class="container mw-md">
                        <div class="container">
                           <div class="row justify-content-center text-center mb-5" v-if="!(!isLoadRelatedPostsApiCallInProgress && relatedPosts.length == 0)">
                              <div class="col-xl-6 col-lg-8">
                                 <h2 class="mb-0 fw-bold">
                                    Related Posts
                                 </h2>
                              </div>
                           </div>
                           <div class="mw-md mx-auto">
                              <ul class="list-unstyled mb-4" v-if="!isLoadRelatedPostsApiCallInProgress && relatedPosts.length > 0">
                                 <li v-for="post in relatedPosts.slice(0, 3)" :key="post.postId">
                                    <PostOutline :post="post" />
                                 </li>
                              </ul>
                              <ul class="list-unstyled mb-4" v-if="isLoadRelatedPostsApiCallInProgress">
                                 <li v-for="index in [1, 2, 3]" :key="index">
                                    <LoadingPostOutline />
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            <div v-else class="forum-content-container">
               <section
                  class="page-title page-title-parallax parallax scroll-detect py-lg-6 scroll-detect-inview text-pop-image"
                  style="--cnvs-scroll-height: 395; --cnvs-scroll-percent: 54.493087557603694; --cnvs-scroll-ratio: 1; --cnvs-scroll-start: 1; --cnvs-scroll-end: 0;"
               >
                  <img :src="appConfigs.assets.communityBannerImage" class="parallax-bg">
                  <div class="container">
                        <div class="page-title-row">
                           <div class="page-title-content mw-xs mx-auto">
                              <h2 class="h1 mb-3 text-center text-pop-image-text">
                                 {{ appConfigs.layout.community.header }}
                              </h2>
                              <p class="color text-larger text-center mt-4 mb-3 text-pop-image-text">
                                 {{ appConfigs.layout.community.headerCaptionAlt }}
                              </p>
                              <form @submit.prevent="searchCommunity()">
                              <div class="input-group input-group-lg mt-4 mb-4 mw-sm mx-auto">
                                 <input
                                    class="form-control rounded-start border-0 fw-semibold"
                                    type="search"
                                    placeholder="Search..."
                                    aria-label="Search"
                                    v-model="searchText"
                                    >
                                 <button
                                    class="btn border-0 bg-white rounded-end h-bg-alt"
                                    type="submit"
                                    >
                                 <i class="uil uil-search"></i>
                                 </button>
                              </div>
                           </form>
                           <div class="text-center">
                              <a
                                 href="/community/submit"
                                 class="button border border-width-2 bg-alt py-2 rounded-1 fw-medium text-transform-none ls-0 ms-0 ms-sm-1 h-op-09">
                              <i class="bi-file-earmark-plus"></i>Create Topic
                              </a>
                              <a
                                 href="/community"
                                 class="button border border-width-2 bg-alt py-2 rounded-1 fw-medium text-transform-none ls-0 ms-0 ms-sm-1 h-op-09">
                              <i class="bi bi-house"></i>Home
                              </a>
                           </div>
                           </div>
                        </div>
                  </div>
               </section>
               <section id="content">
                  <div class="content-wrap pt-4">
                     <div class="container mw-md">
                        <div class="d-inline-flex w-100 my-2">
                           <ul class="d-flex p-0 m-0 w-100 justify-content-between">
                              <li class="forum-home-toolbar">
                                 <label for="topSelect" class="visually-hidden">Select Sort Option</label>
                                 <select class="form-select custom-select-dropdown" id="topSelect" v-model="sortFilter" @change="updateSortFilter($event.target.value)">
                                    <option value="Latest" :selected="sortFilter === 'Latest'">Latest</option>
                                    <option value="Top (Week)" :selected="sortFilter === 'Top (Week)'">Top (Week)</option>
                                    <option value="Top (Month)" :selected="sortFilter === 'Top (Month)'">Top (Month)</option>
                                    <option value="Top (Year)" :selected="sortFilter === 'Top (Year)'">Top (Year)</option>
                                    <option value="Top (All)" :selected="sortFilter === 'Top (All)'">Top (All)</option>
                                 </select>
                              </li>
                              <li class="forum-home-toolbar" v-if="categoryFilter">
                                 <label for="categorySelect" class="visually-hidden">Select Category</label>
                                 <select class="form-select custom-select-dropdown" id="categorySelect" v-model="categoryFilter" @change="updateCategoryFilter($event.target.value)">
                                    <option v-for="tag in appConfigs.layout.community.category" :key="tag.name" :value="tag.name">{{ tag.name }}</option>
                                 </select>
                              </li>
                              <li class="forum-home-toolbar" v-else>
                                 <label for="categorySelect" class="visually-hidden">Select Category</label>
                                 <select class="form-select custom-select-dropdown" id="categorySelect" @change="updateCategoryFilter($event.target.value)">
                                    <option value="" class="selected hidden disabled">Select Category</option>
                                    <option v-for="tag in appConfigs.layout.community.category" :key="tag.name" :value="tag.name">
                                       {{ tag.name }}
                                    </option>
                                 </select>
                              </li>
                           </ul>
                        </div>
                        <div class="d-inline-flex w-100 mt-2 mb-4" v-if="categoryFilter || searchFilter">
                           <span class="me-2" v-if="categoryFilter">
                           <a :href="buildPostQueryString(searchFilter, null, sortFilter)">
                           <span class="badge bg-secondary text-white">
                           <i class="fa-solid fa-tag"></i>&nbsp;&nbsp;{{ categoryFilter }}&nbsp;&nbsp;&nbsp;<i class="fas fa-times"></i>
                           </span>
                           </a>
                           </span>
                           <span class="me-2" v-if="searchFilter">
                           <a :href="buildPostQueryString(null, categoryFilter, sortFilter)">
                           <span class="badge bg-secondary text-white">
                           <i class="fa-solid fa-search"></i>&nbsp;&nbsp;{{ searchFilter }}&nbsp;&nbsp;&nbsp;<i class="fas fa-times"></i>
                           </span>
                           </a>
                           </span>
                        </div>
                        <div class="mw-md mx-auto">
                           <ul class="list-unstyled mb-4" v-if="!isInitialLoadApiCallInProgress">
                              <li v-for="post in posts" :key="post.postId">
                                 <PostOutline :post="post" />
                              </li>
                           </ul>
                           <ul class="list-unstyled mb-4" v-if="isInitialLoadApiCallInProgress">
                              <li v-for="index in [1, 2, 3, 4, 5]" :key="index">
                                 <LoadingPostOutline />
                              </li>
                           </ul>
                           <ul class="pagination justify-content-center">
                              <div
                                 v-if="!isLoadMoreApiCallInProgress && posts?.length !== 0 && (lastStartKey || offset != -1)"
                                 >
                                 <button
                                    type="button"
                                    class="button button-small button-border border-color m-0 color h-bg-color h-text-light mt-2"
                                    @click="loadMorePosts"
                                    >
                                 Load More
                                 </button>
                              </div>
                              <div v-if="isLoadMoreApiCallInProgress && !isInitialLoadApiCallInProgress" class="px-5 py-5">
                                 <LoadingSpinner class="my-3" />
                              </div>
                           </ul>
                           <div v-if="!isInitialLoadApiCallInProgress && posts.length === 0" class="my-5 text-center">
                              <div class="feature-box fbox-effect flex-column">
                                 <div class="fbox-content">
                                    <h3>No Posts</h3>
                                    <p>Be the first to ask a question!</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
      <div class="section bg-transparent my-0 py-0">
         <div class="container-fluid py-6">
            <div class="row justify-content-center mx-auto mw-md gx-5">
               <div class="col">
                  <p class="h6 mb-0">
                     Opinions expressed on this forum reflect the views of our community and do not constitute professional advice. 
                     For further information, please refer to our <a href="/community/guidelines">Community Guidelines.</a> 
                     Have a quesiton? Need to correct some bad information? Have questions about our community?
                     <a href="/about#contact">Contact Us</a>
                  </p>
               </div>
            </div>
         </div>
      </div>
      <div class="modal fade" id="deletePostModal" tabindex="-1" aria-labelledby="deletePostModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row p-4 bg-white d-flex align-items-center justify-content-center rounded">
                     <div class="col-md-12 p-4">
                        <h3 class="mb-2">
                           Delete Post
                        </h3>
                        <p class="mb-4 fw-normal text-smaller text-black-50">
                           Are you sure you want to delete this post?
                        </p>
                        <button type="button" class="button button-large m-0 bg-color text-light h-op-08" @click="deletePost" data-bs-dismiss="modal">Delete</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script lang="ts">
import { timeSince }from '@/utils/time';
import { Options, Vue } from 'vue-class-component';
import store, { VuexModuleNamespaces, DataStore } from '@/store';
import { createComment, deletePost, getPost, getPostCommentLikes, getPostComments, getPostLikes, likePost, listPosts, listTopPosts } from '@/service/community';
import { LastStartKey, Post, PostComment, PostCommentLike, PostLike, PostList } from '@/models/Community';
import { APP_CONFIGS } from '@/main';
import LoadingBox from '@/components/loading/LoadingBox.vue';
import LoadingPost from '@/components/loading/LoadingPost.vue';
import LoadingComment from '@/components/loading/LoadingComment.vue';
import ProfileThumbnail from '@/components/user/ProfileThumbnail.vue';
import Comment from '@/components/community/Comment.vue';
import TextEditor from '@/components/misc/TextEditor.vue';
import { flagContent } from '@/utils/flag';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import LoadingPostOutline from '@/components/loading/LoadingPostOutline.vue';
import PostOutline from '@/components/community/PostOutline.vue';
import { FrameControl } from '@/models/FrameControl';
import { formatNumber } from '@/utils/numbers';
import { showSignIn } from '@/utils/user';


@Options({
  components: {
      LoadingBox,
      LoadingSpinner,
      LoadingComment,
      ProfileThumbnail,
      Comment,
      TextEditor,
      LoadingPost,
      PostOutline,
      LoadingPostOutline
   }
})
export default class CommunityPostFeedView extends Vue {

   sortFilter = "";
   categoryFilter = "";
   searchFilter = "";
   offset = 0;
   lastStartKey: LastStartKey | undefined | null;

   postList: PostList | null = null;
   currentPosts: Post[] = [];
   relatedPosts: Post[] = [];

   postDetails: Post | null = null;
   postComments: PostComment[] = [];
   postLikes: PostLike[] = [];
   postCommentLikes: PostCommentLike[] = [];
   showCommentViewTopLevelCommentId: string | null = null;

   searchText = "";

   isInitialLoadApiCallInProgress = false;
   isLoadMoreApiCallInProgress = false;
   isLoadPostApiCallInProgress = false;
   isSubmitCommentApiCallInProgress = false;
   isLoadRelatedPostsApiCallInProgress = false;

   minInvalidCommentLength = 5
   maxInvalidCommentLength = 2000

   isShowInvalidComment = false;

   showEditor = false;

   public async mounted(): Promise <void> {
      this.categoryFilter = this.$route.query.category as string;
      this.sortFilter = this.$route.query.sort as string;
      this.searchFilter = this.$route.query.search as string;

      // If sort filter is blank, then set to latest
      if (!this.sortFilter) {
         this.sortFilter = "Latest"
      } 

      // If sort filter is generic "Top", then set to Top (Week)
      if (this.sortFilter === "Top") {
         this.sortFilter = "Top (Week)"
      } else if (this.sortFilter === "latest") {
         this.sortFilter = "Latest"
      }

      // Listen for comment refresh events
      window.addEventListener('xframe:refreshComments', () => {
         this.refreshComments();
      }, false);
   
      // Listen for comment likes refresh events
      window.addEventListener('xframe:refreshCommentLikes', () => {
         this.refreshCommentLikes();
      }, false);

      // Listen for show comment events 
      window.addEventListener('xframe:showComments', async (event) => {
         this.showCommentViewTopLevelCommentId = (event as CustomEvent).detail.parentCommentId as string;
         await new Promise(r => setTimeout(r, 100));
         document.getElementById('back-to-discussion')?.scrollIntoView({ behavior: 'auto', block: 'start' }); window.scrollBy(0, -100);
      }, false);

      // Listen for show comment events 
      window.addEventListener('xframe:showParentCommentsEvent', () => {
         this.showCommentViewTopLevelCommentId = null;
      }, false);

      // Reset validators
      this.resetValidators();

      // Load post
      if (this.postId) {
         await this.loadPost(this.postId);
      }

      // Refresh posts
      if (this.isTopActive) {
         await this.loadTopPosts();
      } else {
         await this.loadLatestPosts(undefined);
      }

      this.$forceUpdate();
   }

   public resetValidators(): void {
      this.isShowInvalidComment = false;
   }

   public async loadPost(postId: string): Promise <void> {
      try {
         this.isLoadPostApiCallInProgress = true

         this.postDetails = await (await getPost(postId)).data;
         this.postLikes = (await (await getPostLikes(postId)).data);
         this.postComments = (await (await getPostComments(postId)).data);
         this.postCommentLikes = (await (await getPostCommentLikes(postId)).data);
         this.setRelatedPosts(this.postDetails.category, this.postDetails.postId);

         // Scroll to top
         window.scrollTo(0, 0);
      } catch (err) {
         console.log(err);
      } finally {
         this.isLoadPostApiCallInProgress = false;
      }
   }

   public timeSince(epochMilliseconds: number): string {
      return timeSince(epochMilliseconds);
   }

   public async loadTopPosts(): Promise<void> {
      // Reset posts
      this.currentPosts = [];

      try {
         this.isInitialLoadApiCallInProgress = true;
         this.postList = await (await listTopPosts(this.categoryFilter, this.searchFilter, this.offset, this.searchDays)).data;
         this.currentPosts.push(...this.postList.posts);
         if (this.postList.lastStartKey.offset) {
            this.offset = this.postList.lastStartKey.offset;
         }
      } catch (err) {
         console.log(err);
      } finally {
         this.isInitialLoadApiCallInProgress = false;
      }
   }

   public async loadLatestPosts(lastStartKey: LastStartKey | undefined | null): Promise <void> {
      // Reset posts
      this.currentPosts = [];

      try {
         this.isInitialLoadApiCallInProgress = true;
         this.postList = await (await listPosts(this.categoryFilter, this.searchFilter, lastStartKey)).data;
         this.currentPosts.push(...this.postList.posts);
         this.lastStartKey = this.postList.lastStartKey;
      } catch (err) {
         console.log(err);
      } finally {
         this.isInitialLoadApiCallInProgress = false;
      }
   }

   public async deletePost(): Promise<void> {
      try {
         if (this.postId) {
            await (await deletePost(this.postId)).data;
            location.href = "/community";
         }
        } catch(err) {
            store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
        }
    }

   public async updateCategoryFilter(update: string | null): Promise<void> {
      if (update) {
         this.categoryFilter = update;
      }

      // Refresh posts
      if (this.isTopActive) {
         this.offset = 0;
         await this.loadTopPosts();
      } else {
         await this.loadLatestPosts(undefined);
      }

      // Update route
     const router = this.$router;
     const currentRoute = router.currentRoute.value;
      router.push({
        ...currentRoute,
        query: {
          ...currentRoute.query,
         category: update,
        },
      });
   }

   public async updateSortFilter(update: string | null): Promise<void> {
      if (update) {
         this.sortFilter = update;
      }

      // Refresh posts
      if (this.isTopActive) {
         this.offset = 0;
         await this.loadTopPosts();
      } else {
         await this.loadLatestPosts(undefined);
      }

      // Update route
     const router = this.$router;
     const currentRoute = router.currentRoute.value;
      router.push({
        ...currentRoute,
        query: {
          ...currentRoute.query,
          sort: update,
        },
      });
   }

   public async loadMorePosts(): Promise <void> {
      if (this.isTopActive) {
         try {
            this.isLoadMoreApiCallInProgress = true;
            this.postList = await (await listTopPosts(this.categoryFilter, this.searchFilter, this.offset, this.searchDays)).data;
            this.currentPosts.push(...this.postList.posts);
            if (this.postList.lastStartKey.offset) {
               this.offset = this.postList.lastStartKey.offset;
            }
         } catch (err) {
            console.log(err);
         } finally {
            this.isLoadMoreApiCallInProgress = false;
         }
      } else {
         try {
            this.isLoadMoreApiCallInProgress = true;
            this.postList = await (await listPosts(this.categoryFilter, this.searchFilter, this.lastStartKey)).data;
            this.currentPosts.push(...this.postList.posts);
            this.lastStartKey = this.postList.lastStartKey;
         } catch (err) {
            console.log(err);
         } finally {
            this.isLoadMoreApiCallInProgress = false;
         }
      }
   }

   public async createComment(): Promise<void> {
      if (!this.isLoggedIn) {
        showSignIn();
      }
      
      // Reset validators
      this.resetValidators();

      // Get content
     const content = (this.$refs.commentPostTextEditor as TextEditor).content as string;
      
      // Set contents
     const contents = {
         "content": content,
      }

      // Validate form
      if (!content || content.length > this.maxInvalidCommentLength || content.length < this.minInvalidCommentLength) {
         this.isShowInvalidComment = true
         return;
      }

      // Create and refresh comment
      try {
         if (this.postId) {
            this.isSubmitCommentApiCallInProgress = true
            await (await createComment(this.postId, contents)).data;
            this.postComments = (await (await getPostComments(this.postId)).data);
            this.showEditor = false;
            this.$forceUpdate();
         }
      } catch(err) {
         store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
      } finally {
         this.isSubmitCommentApiCallInProgress = false
      }
   }

   public formatNumber(num: number): string {
      return formatNumber(num);
   }

   public sortPostsByTop(posts: Post[]): Post[] {
      return posts.sort((a, b) => {
        const aViews = Number(a.views);
        const bViews = Number(b.views);
         return aViews > bViews ? -1 : aViews < bViews ? 1 : 0
      })
   }

   public goToPost(postId: string): void {
      this.loadPost(postId);
      this.$router.push({
         "path": `/community/posts/${postId}`
      })
   }

   public async refreshComments(): Promise <void> {
      if (this.postId) {
         this.postComments = (await (await getPostComments(this.postId)).data);
         this.postCommentLikes = (await (await getPostCommentLikes(this.postId)).data);
      }
   }

   public async refreshCommentLikes(): Promise <void> {
      if (this.postId) {
         this.postCommentLikes = (await (await getPostCommentLikes(this.postId)).data);
      }
   }

	public async likePost(): Promise<void> {
      if (!this.isLoggedIn) {
        showSignIn();
      }
      
      if (this.postId) {
         try {
            await (await likePost(this.postId)).data;
            await this.refreshPostLikes(this.postId);
            this.$forceUpdate()
         } catch(err) {
            console.log(err);
         }
         this.$forceUpdate();
      }
	}

   public async setRelatedPosts(category: string, postId: string): Promise<void> {
      try {
         this.isLoadRelatedPostsApiCallInProgress = true;
        const posts = await (await listPosts(category, undefined, undefined)).data;
         this.relatedPosts = posts.posts.filter(post => post.postId !== postId).slice(0, 3);
      } catch (err) {
         console.log(err);
      } finally {
         this.isLoadRelatedPostsApiCallInProgress = false;
      }
   }

   public goToPreviousPost(postId: string | undefined): void {
      if (this.postId) {
        const targetPost: Post | undefined = this.posts[this.posts.findIndex(post => post.postId === postId) - 1];
         if (targetPost) {
            this.goToPost(targetPost.postId);
         }
      }
   }

   public goToNextPost(postId: string | undefined): void {
      if (this.postId) {
        const targetPost: Post | undefined = this.posts[this.posts.findIndex(post => post.postId === postId) + 1];
         if (targetPost) {
            this.goToPost(targetPost.postId);
         }
      }
   }

   public goBack(): void {
      this.$router.go(-1);
   }

   public async refreshPostLikes(postId: string): Promise<void> {
		try {
			this.postLikes = (await getPostLikes(postId)).data;
		} catch(err) {
			console.log(err);
		}
	}

   public toggleShowEditor(update: boolean) {
      this.showEditor = update;
      if (this.showEditor == false) {
         this.resetValidators();
      }
  }

  public flagContent(content: string): void {
      flagContent(content);
   }

   public searchCommunity(): void {
     const queryString = this.buildPostQueryString(
         this.searchText,
         this.categoryFilter,
         this.sortFilter
      )
      window.location.href = queryString;
   }

   public buildPostQueryString(
      searchFilter: string | null,
     categoryFilter: string | null,
      sortFilter: string | null
      ): string {
         let queryString = "";
         if (categoryFilter && categoryFilter.length > 0) {
            queryString += `category=${categoryFilter}`;
         }
         if (sortFilter && sortFilter.length > 0) {
            if (queryString.length > 0) {
               queryString += "&";
            }
            queryString += `sort=${sortFilter}`;
         }
         if (searchFilter && searchFilter.length > 0) {
            if (queryString.length > 0) {
               queryString += "&";
            }
            queryString += `search=${searchFilter}`;
         }
         if (queryString.length > 0) {
            return `/community/posts?${queryString}`
         } else {
            return `/community/posts`
         }
   }

   get posts(): Post[] {
      let posts = this.currentPosts;

      // Deduplicate posts
      posts = posts.reduce((accumulator: Post[], currentPost: Post) => {
         if (!accumulator.some(post => post.postId === currentPost.postId)) {
            accumulator.push(currentPost);
         }
         return accumulator;
      }, []);

      // Sort posts, if necessary
      if (this.isTopActive) {
         if (this.postList) {
            posts = this.sortPostsByTop(posts);
         }
      }

      // Dedupe posts
      return posts;
   }

   get assetHost(): string {
      return APP_CONFIGS.api.assetHost;
   }

   get appConfigs(): FrameControl {
      return APP_CONFIGS;
   }

   get postId(): string | undefined {
      return this.$route.params.postId as string | undefined;
   }

   get showPostView(): boolean {
      if (this.postId) {
         return true;
      } else {
         return false;
      }
   }

   get isOwnPost(): boolean {
      return this.postDetails?.userId === this.userId;
   }

   get username(): string {
      return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUsername.name}`];
   }

   get userId(): string {
      return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUserId.name}`];
   }
   
   get parentComments(): PostComment[] {
      if (this.showCommentViewTopLevelCommentId) {
         return this.postComments?.filter(x => (x.commentId === this.showCommentViewTopLevelCommentId)) as PostComment[];
      } else {
         return this.postComments?.filter(x => (!x?.parentCommentId)) as PostComment[];
      }
   }

   get isShowCommentsMode(): boolean {
      if (this.showCommentViewTopLevelCommentId) {
         return true;
      } else {
         return false;
      }
   }

   get isLatestActive(): boolean {
      return this.sortFilter === "Latest";
   }

   get searchDays(): number {
      if (this.sortFilter === "Top (Week)") {
         return 7;
      } else if (this.sortFilter === "Top (Month)") {
         return 31;
      } else if (this.sortFilter === "Top (Year)") {
         return 365;
      } else if (this.sortFilter === "Top (All)") {
         return 50000;
      }
      return 0;
   }

   get isTopActive(): boolean {
      if (this.sortFilter) {
         return this.sortFilter.includes("Top");
      } else {
         return false;
      }
   }

   get isLoggedIn(): boolean {
        return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getIsLoggedIn.name}`];
    }

}
</script>

<style scoped>
.lightbox-preview-image {
   min-height: 100%;
   object-fit: cover;
}
.lightbox-preview-row {
   padding-bottom: calc(var(--bs-gutter-x)* 0.5);
   padding-top: calc(var(--bs-gutter-x)* 0.5);
}
.signup-box {
   background-color: #ffffff;
   border: 1px solid #dddddd;
   padding: 30px;
   text-align: center;
}
.signup-box h2 {
   font-size: 24px;
   margin-bottom: 10px;
}
.signup-box p {
   font-size: 16px;
   color: #666666;
   margin-bottom: 30px;
}
.signup-btn {
   background-color: #000000;
   color: #ffffff;
   padding: 10px 30px;
   border: none;
   font-size: 16px;
   cursor: pointer;
}
.custom-select-dropdown {
   margin: 0 !important;
   border: none !important;
   padding-right: 30px;
   padding-top: 0 !important;
   padding-bottom: 0 !important;
   padding-left: 0 !important;
}
.forum-home-toolbar {
    text-transform: none !important;
    cursor: pointer;
    list-style-type: none;
}
</style>