<template>
    <div class="loading-box"></div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class LoadingBox extends Vue {

}
</script>

<style scoped>
.loading-box {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #e0e0e0;
    opacity: 0.5;
}
</style>
    

