<template>
    <ol class="commentlist p-0 m-0" style="border-bottom: 0 !important;">
       <li class="comment even thread-even depth-1" id="li-comment-1">
          <div id="comment-1" class="comment-wrap">
             <div class="comment-meta">
                <div class="comment-author vcard">
                   <a class="comment-avatar" :href="'/user/' + review.userId">
                   <img
                      alt="Image"
                      :src="review.user?.userThumbnail ? assetHost + '/' + review.user?.userThumbnail : '/img/profile_not_found.png'"
                      onerror="this.onerror=null; this.src='/img/profile_not_found.png'"
                      class="avatar avatar-60 photo avatar-default"
                      height="60"
                      width="60"
                      >
                   </a>
                </div>
             </div>
             <div class="comment-content">
                <div class="comment-author">
                   <a :href="'/user/' + review.userId">
                   {{ review.user?.username ?? "Unknown" }}
                   </a>
                   <span class="d-flex">
                   {{ timeSince(Number(review.created)) }} ago
                   <span class="ms-1" v-if="review.edited">
                   &nbsp;(Edited {{ timeSince(Number(review.edited)) }} ago)&nbsp;
                   </span>
                   </span>
                </div>
                <div style="margin-top: 0.95rem;">
                   <StarRating :rating="Number(review.rating)" />
                </div>
                <div>
                   <div v-html="review?.content" class="review-body-text rendered-html"></div>
                </div>
                <div class="review-images d-flex flex-wrap">
                   <div v-for="attachment in review.attachments" :key="attachment" class="mx-2 my-2">
                      <a :href="assetHost + '/' + attachment" target="_blank">
                        <img
                           :src="assetHost + '/' + attachment"
                           alt="Image Description"
                        >
                      </a>
                   </div>
                </div>
                <a 
                   class="comment-reply-link"
                   href="#"
                   id="editlink2"
                   data-bs-toggle="dropdown"
                   aria-expanded="false"
                   >
                <i class="bi bi-three-dots"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-links rounded shadow-sm dropdown-menu-end py-0 m-0" aria-labelledby="editlink2">
                   <a
                      class="dropdown-item"
                      @click="editReview(review.reviewId)"
                      v-if="isOwnReview(review.userId)"
                      >
                   <i class="bi bi-pencil me-1"></i> Edit
                   </a>
                   <a
                      class="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteReviewModal"
                      v-if="isOwnReview(review.userId)"
                      >
                   <i class="bi bi-trash me-1"></i> Delete
                   </a>
                   <a
                      class="dropdown-item"
                      href="#"
                      @click="flagContent('Review ' + review.reviewId)"
                      v-if="!isOwnReview(review.userId)"
                      >
                   <i class="bi bi-flag me-1"></i> Flag
                   </a>
                </div>
             </div>
             <div class="clear"></div>
          </div>
       </li>
    </ol>
    <div class="modal fade" id="deleteReviewModal" tabindex="-1" aria-labelledby="deleteReviewModalLabel" aria-hidden="true">
       <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
             <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
             </div>
             <div class="modal-body">
                <div class="row p-4 bg-white d-flex align-items-center justify-content-center rounded">
                   <div class="col-md-12 p-4">
                      <h3 class="mb-2">
                         Delete Review
                      </h3>
                      <p class="mb-4 fw-normal text-smaller text-black-50">
                         Are you sure you want to delete this review?
                      </p>
                      <button type="button" class="button button-large m-0 bg-color text-light h-op-08" @click="deleteReview">Delete</button>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </template>

<script lang="ts">
import { VetReview } from '@/models/Vet';
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { timeSince } from '@/utils/time';
import { APP_CONFIGS } from '@/main';
import StarRating from '../misc/StarRating.vue';
import store, { DataStore, VuexModuleNamespaces } from '@/store';
import { deleteVetReview } from '@/service/vet';
import { flagContent } from '@/utils/flag';

@Options({
    props: {
        vetId: {
            required: true,
            type: String,
        },
        review: {
            required: true,
            type: Object as PropType<VetReview>
        },
    },
    components: {
        StarRating
    }
})
export default class VetReviewPost extends Vue {

    vetId!: string;
    review!: VetReview;

    public timeSince(epochMilliseconds: number): string {
        return timeSince(epochMilliseconds);
    }

    public flagContent(content: string): void {
        flagContent(content);
    }

    public async deleteReview(): Promise<void> {
        try {
            await (await deleteVetReview(this.vetId, this.review.reviewId)).data;
            location.href = `/vet/${this.vetId}`;
        } catch(err) {
            store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
        }
    }

    public editReview(reviewId: string): void {
        window.location.href = `/vet/${this.vetId}/review?edit=${reviewId}`;
    }

    public isOwnReview(reviewUserId: string): boolean {
        return reviewUserId === this.userId;
    }

    get assetHost(): string {
        return APP_CONFIGS.api.assetHost;
    }

    get userId(): string {
        return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUserId.name}`];
    }

}
</script>


<style scoped>
.review-body-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-bottom: 1rem;
}
.review-images img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 4px;
    border-radius: 4px;
    cursor: pointer;
}
.rendered-html p {
    margin-bottom: 0 !important;
}
.modal-header {
   border-bottom: 0;
}
</style>
