<template>
    <div class="col-md-4 d-flex flex-column text-center align-items-center">
        <div class="skeleton">
            <div class="d-flex align-items-center mb-4">
                <div class="skeleton-avatar mb-0"></div>
            </div>
            <ul class="skeleton-para list-unstyled mb-0">
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    </div>
    <div class="col-md-8 p-4 testi-content">
        <div class="skeleton">
            <ul class="skeleton-para list-unstyled mb-0">
                <li></li>
                <li></li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({

})
export default class LoadingVetReviewPreview extends Vue {


}
</script>


<style scoped>

</style>
