<template>
    <div>
       <div v-if="commentLevel < 4">
          <div v-if="isShowCommentsMode" class="my-4" id="back-to-discussion">
             <a
                style="cursor: pointer;"
                @click="emitShowParentCommentsEvent()"
             >
                <i class="bi bi-arrow-counterclockwise"></i> Back to Discussion
            </a>
          </div>
            <div class="mb-3">
                <ol class="commentlist p-0 m-0" style="border-bottom: 0 !important;">
                    <li class="comment even thread-even depth-1" id="li-comment-1">
                        <div id="comment-1" class="comment-wrap">
                            <div class="comment-meta">
                                <div class="comment-author vcard">
                                <a class="comment-avatar" :href="'/user/' + comment.userId">
                                <img
                                    alt="Image"
                                    :src="comment.user?.userThumbnail ? assetHost + '/' + comment.user?.userThumbnail : '/img/profile_not_found.png'"
                                    onerror="this.onerror=null; this.src='/img/profile_not_found.png'"
                                    class="avatar avatar-60 photo avatar-default"
                                    height="60"
                                    width="60"
                                    >
                                </a>
                                </div>
                            </div>
                            <div class="comment-content">
                                <div class="comment-author">
                                    <a :href="'/user/' + comment.userId">
                                        {{ comment.user?.username ?? "Unknown" }}
                                    </a>
                                    <span class="d-flex">
                                        {{ timeSince(Number(comment.created)) }} ago
                                        <span class="ms-1" v-if="comment.edited">
                                            &nbsp;(Edited {{ timeSince(Number(comment.edited)) }} ago)&nbsp;
                                        </span>
                                    </span>
                                </div>
                                <div v-if="!comment.deleted">
                                    <div v-html="comment?.content" class="comment-body-text rendered-html"></div>
                                </div>
                                <div v-else>
                                    <div v-html="'<p>[ Deleted ]</p>'" class="comment-body-text rendered-html"></div>
                                </div>
                                <div class="mt-3">
                                    <div class="btn-group">
                                       <button
                                          type="button"
                                          class="btn btn-sm btn-outline-secondary btn-outline-no-border color h-bg-color h-text-light mt-2"
                                          @click="likeComment"
                                          >
                                       <i class="fa-regular fa-thumbs-up"></i> Like <span v-if="likeCount > 0">({{ likeCount }})</span>
                                       </button>
                                       <button
                                          type="button"
                                          class="btn btn-sm btn-outline-secondary btn-outline-no-border color h-bg-color h-text-light mt-2"
                                          @click="openReply()"
                                          >
                                       <i class="fa-regular fa-comment"></i> Reply
                                       </button>
                                    </div>
                                </div>
                                    <a 
                                        class="comment-reply-link"
                                        href="#"
                                        id="editlink2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i class="bi bi-three-dots"></i>
                                    </a>
                                <div class="dropdown-menu dropdown-menu-links rounded shadow-sm dropdown-menu-end py-0 m-0" aria-labelledby="editlink2">
                                    <a
                                        class="dropdown-item"
                                        :href="'/community/submit?edit=' + comment.postId + '&comment=' + comment.commentId"
                                        v-if="isOwnComment"
                                    >
                                        <i class="bi bi-pencil me-1"></i> Edit
                                    </a>
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        :data-bs-target="'#' + deleteCommentModalId"
                                        v-if="isOwnComment"
                                    >
                                        <i class="bi bi-trash me-1"></i> Delete
                                    </a>
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    @click="flagContent('Comment ' + comment.commentId)"
                                    v-if="!isOwnComment"
                                    >
                                <i class="bi bi-flag me-1"></i> Flag
                                </a>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div v-if="showEditor" class="my-3">
                            <div>
                                <TextEditor ref="commentPostTextEditor" />
                                <button
                                    type="submit"
                                    class="button m-0 bg-color text-light h-op-08 me-2 mt-2"
                                    @click="createComment(comment?.commentId)"
                                    >
                                    Submit
                                </button>
                                <button
                                    class="button button-border border-color m-0 color h-bg-color h-text-light mt-2"
                                    @click="closeEditor()"
                                    >
                                    Close
                                </button>
                                <div class="my-2 text-danger" v-if="isShowInvalidComment">
                                    Comment must be between 5 - 2000 characters. 
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
       </div>
       <ul v-for="reply in replyComments" :key="reply.commentId" style="margin-bottom: 0 !important;">
          <Comment
             :postComments="postComments"
             :postCommentLikes="postCommentLikes"
             :postId="postId"
             :commentId="reply.commentId"
             :commentLevel="commentLevel + 1"
             />
       </ul>
    </div>
    <div v-if="commentLevel === 4 && isFirstSameLevelComment" class="my-4">
        <a
            style="cursor: pointer;"
            @click="emitShowMoreCommentsEvent()"
            >
            <i class="bi bi-plus-circle"></i> More Comments
        </a>
    </div>
    <div class="modal fade" :id="deleteCommentModalId" tabindex="-1" aria-labelledby="deleteCommentModalLabel" aria-hidden="true">
       <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
             <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
             </div>
             <div class="modal-body">
                <div class="row p-4 bg-white d-flex align-items-center justify-content-center rounded">
                   <div class="col-md-12 p-4">
                      <h3 class="mb-2">
                         Delete Comment
                      </h3>
                      <p class="mb-4 fw-normal text-smaller text-black-50">
                        Are you sure you want to delete this comment?
                      </p>
                      <button type="button" class="button button-large m-0 bg-color text-light h-op-08" @click="deleteComment" data-bs-dismiss="modal">Delete</button>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { timeSince }from '@/utils/time';
import ProfileThumbnail from '@/components/user/ProfileThumbnail.vue';
import TextEditor from '@/components/misc/TextEditor.vue';
import { PostComment, PostCommentLike } from '@/models/Community';
import { createComment, deleteComment, likeComment } from '@/service/community';
import store, { DataStore, VuexModuleNamespaces } from '@/store';
import LoadingBox from '@/components/loading/LoadingBox.vue';
import { flagContent } from '@/utils/flag';
import { showSignIn } from '@/utils/user';
import { APP_CONFIGS } from '@/main';


@Options({
    props: {
        postComments: {
            required: true,
            type: Object as PropType<PostComment[]>
        },
        postCommentLikes: {
            required: true,
            type: Object as PropType<PostCommentLike[]>
        },
        commentId: {
            required: true,
            type: String
        },
        postId: {
            required: true,
            typle: String
        },
        commentLevel: {
            required: true,
            type: Number
        },
        isShowCommentsMode: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    components: {
      ProfileThumbnail,
      TextEditor,
      LoadingBox
    }
})
export default class Comment extends Vue {

    postId!: string;
    commentId!: string;
    postComments!: PostComment[];
    postCommentLikes!: PostCommentLike[];
    commentLevel!: number;

    isShowCommentsMode!: boolean;

    showEditor = false;
    isApiCallInProgress = false;

    minInvalidCommentLength = 5
    maxInvalidCommentLength = 2000

    deleteCommentModalId = Math.random().toString(36).slice(2);  
    
    isShowInvalidComment = false;

    public mounted(): void {
        // Reset validators
        this.resetValidators();
    }
    
    public resetValidators(): void {
        this.isShowInvalidComment = false;
    }

    public timeSince(epochMilliseconds: number | undefined): string {
        return timeSince(epochMilliseconds);
    }

    public async createComment(parentCommentId: string | undefined): Promise<void> {
        if (!this.isLoggedIn) {
            showSignIn();
        }

        // Reset validators
        this.resetValidators();

        // Get content
        const content = (this.$refs.commentPostTextEditor as TextEditor).content as string;
        
        // Set contents
        const contents = {
            "content": content,
            "parentCommentId": parentCommentId
        }

        // Validate form
        if (!content || content.length > this.maxInvalidCommentLength || content.length < this.minInvalidCommentLength) {
            this.isShowInvalidComment = true
            return;
        }

        try {
            this.isApiCallInProgress = true;
            await (await createComment(this.postId, contents)).data;
            this.emitRefreshEvent();
            this.showEditor = false;
        } catch(err) {
            store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
        } finally {
            this.isApiCallInProgress = false;
        }
    }

    public async likeComment(): Promise<void> {
        if (!this.isLoggedIn) {
            showSignIn();
        }
        
        try {
            this.isApiCallInProgress = true;
            await (await likeComment(this.postId, this.commentId)).data;
            this.emitRefreshLikes();
            this.showEditor = false;
        } catch(err) {
            console.log(err);
        } finally {
            this.isApiCallInProgress = false;
        }
    }

    public closeEditor(): void {
        this.showEditor = false;
        (this.$refs.commentPostTextEditor as TextEditor).clearContent();
    }

    public openReply(): void {
        this.showEditor = true;
    }

    public emitRefreshEvent(): void {
        const event = new Event('xframe:refreshComments', {
            cancelable: true
        });
      window.dispatchEvent(event);
    }

    public emitRefreshLikes(): void {
        const event = new Event('xframe:refreshCommentLikes', {
            cancelable: true
        });
      window.dispatchEvent(event);
    }

    public emitShowMoreCommentsEvent(): void {
        const event = new CustomEvent('xframe:showComments', {
            cancelable: true,
            detail: {
                parentCommentId: this.comment.parentCommentId
            }
        });
      window.dispatchEvent(event);
    }

    public emitShowParentCommentsEvent(): void {
        const event = new Event('xframe:showParentCommentsEvent', {
            cancelable: true
        });
      window.dispatchEvent(event);
    }

    public flagContent(content: string): void {
        flagContent(content);
    }

    public async deleteComment(): Promise<void> {
        try {
            this.isApiCallInProgress = true;
            await (await deleteComment(this.postId, this.commentId)).data;
            this.emitRefreshEvent();
        } catch(err) {
            store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
        } finally {
            this.isApiCallInProgress = false;
        }
    }

    get sameLevelComments(): number {
        return this.postComments.filter(comment => this.comment.parentCommentId === comment.parentCommentId).length;
    }

    get isFirstSameLevelComment(): boolean {
        const sameLevelComments = this.postComments.filter(comment => this.comment.parentCommentId === comment.parentCommentId);
        if (sameLevelComments && sameLevelComments.length > 0) {
            const firstSamelevelComment = sameLevelComments[0];
             return firstSamelevelComment.commentId === this.commentId;
        } else {
            return false;
        }
    }

    get replyComments(): PostComment[] {
        return this.postComments.filter(x => x.parentCommentId === this.commentId);
    }

    get comment(): PostComment {
        return this.postComments.filter(x => x.commentId === this.commentId)[0];
    }

    get likeCount(): number {
        return this.postCommentLikes.filter(x => x.commentId === this.commentId).length
    }

    get isOwnComment(): boolean {
        return this.postComments.filter(x => x.commentId === this.commentId && x.userId === this.userId).length > 0;
    }

    get userId(): string {
        return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUserId.name}`];
    }

    get isLoggedIn(): boolean {
        return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getIsLoggedIn.name}`];
    }

    get assetHost(): string {
        return APP_CONFIGS.api.assetHost;
    }
}
</script>
  
<style scoped lang="scss">
ol, ul {
    padding-left: 2rem;
}
.btn-outline-no-border {
    border: none !important;
}
.modal-header {
   border-bottom: 0;
}
.comment-body-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-bottom: 1rem;
}
</style>
