<template>
    <div class="textEditor">
        <div v-if="enableImage">
            <div v-if="enableImage">
                <label for="file-upload" class="button button-small button-border border-color color h-bg-color h-text-light me-2 image-and-video">
                    <input
                        id="file-upload"
                        type="file"
                        accept="image/png, image/jpeg"
                        multiple style="display: none;"
                        @change="onFileChange"
                    >
                    <i class="fa-solid fa-paperclip"></i>&nbsp;Attachments
                </label>  
            </div>
        </div>
        <div v-if="imagePreview" class="d-flex flex-wrap">
            <div v-for="(image, index) in editAttachments" :key="image" class="mx-2 mb-2">
                <div class="thumbnail-container">
                    <span class="thumbnail-icon" @click="removeEditImage(index)">
                        <i class="fa-regular fa-circle-xmark" style="background-color: white;"></i>
                    </span>
                    <img :src="assetHost + '/' + image" class="preview-image">
                </div>
            </div>
            <div v-for="(image, index) in previewImages" :key="image.id" class="mx-2 mb-2">
                <div class="thumbnail-container">
                    <span class="thumbnail-icon" @click="removeImage(index)">
                        <i class="fa-regular fa-circle-xmark" style="background-color: white;"></i>
                    </span>
                    <img :src="image.source" class="preview-image">
                </div>
            </div>
        </div>
        <div>
            <QuillEditor
                :toolbar="toolbarOptions"
                theme="snow"
                contentType="html"
                v-model:content="content"
            />
        </div>
    </div>
</template>
  
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { QuillEditor } from '@vueup/vue-quill'

import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { ImageAsset } from '@/models/ImageAsset';
import { APP_CONFIGS } from '@/main';

@Options({
    components: {
        QuillEditor
    },
    props: {
        enableImage: {
            required: false,
            default: false,
            type: Boolean
        },
        minHeight: {
            required: false,
            default: 200,
            type: Number
        },
        defaultContent: {
            required: false,
            type: String
        }
    }
})
export default class TextEditor extends Vue {

    content = "";
    fileInput: HTMLInputElement | null = null;
    defaultContent!: string
    enableImage!: boolean;

    // Images
    imagePreview: string[] = [];
    editAttachments: string[] = [];

    public mounted(): void {
        // Set file input
        this.fileInput = document.getElementById('file-upload') as HTMLInputElement;

        // Set default content
        if (this.defaultContent) {
            this.content = this.defaultContent;
        }
    }

    public removeEditImage(index: number): void {
        this.editAttachments.splice(index, 1);
    }

    public removeImage(index: number): void {
        this.imagePreview.splice(index, 1)
        this.removeFileByIndex(index);
    }

    public setContent(content: string): void {
        this.content = content;
    }

    public async setAttachments(attachments: string[]): Promise<void> {
        // Build list of urls
        const urls: string[] = [];
        if (attachments) {
            attachments.forEach(attachment => {
                urls.push(
                    this.assetHost + "/" + attachment
                )
            });
            this.editAttachments = attachments;
        }
    }

    public removeFileByIndex(index: number): void {
        if (this.fileInput && this.fileInput.files && this.fileInput.files.length > index) {
            const updatedFiles: File[] = Array.from(this.fileInput.files) as File[];
            updatedFiles.splice(index, 1);
            const updatedDataTransfer = new DataTransfer();
            updatedFiles.forEach(file => {
                updatedDataTransfer.items.add(file);
            });
            this.fileInput.files = updatedDataTransfer.files;
        }
    }

    public createImage(files: File[]): void {
        for (let index = 0; index < files.length; index++) {
            const reader = new FileReader();
            reader.onload = event => {
                const imageUrl = event.target?.result;
                this.imagePreview.push(imageUrl as string);
            }
            reader.readAsDataURL(files[index]);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onFileChange(event: any): void {
        const files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
            return
        }
        this.createImage(files);
    }

    public clearContent(): void {
        this.content = "";
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get toolbarOptions(): any {
        return [
            ['bold', 'italic', 'underline'],
            ['link'],
            []
        ];
    }

    get assetHost(): string {
        return APP_CONFIGS.api.assetHost;
    }

    get previewImages(): ImageAsset[] {
        const previewImages: ImageAsset[] = [];
        this.imagePreview.forEach(image => {
            previewImages.push({
                "id": image,
                "description": "preview",
                "source": image
            });
        })
        return previewImages;
    }
}
</script>
  
<style lang="scss">

.thumbnail-container {
    display: inline-flex;
}
.thumbnail-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99; 
  cursor: pointer;
}
.ql-snow {
    box-sizing: border-box;
    font-family: inherit;
}
.ql-editor {
    min-height: 100px;
}
.image-and-video {
    margin-bottom: 10px;
}
.thumbnail-container {
    position: relative;
}
.thumbnail-icon {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
.preview-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 4px;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px;
    position: relative;
}
</style>
  