import axios, { AxiosResponse } from "axios";
import { Auth } from "aws-amplify";
import { ActivityList, NotificationList, Username, UserNotificationPreferences } from "@/models/User";
import config from '../../configs/configs.json';
import { FrameControl } from "@/models/FrameControl";
import { PostPresignedUrl } from "@/models/ImageAsset";

export const CONFIGS: FrameControl = config;
export const PUBLIC_HOST = CONFIGS.api.publicHost;
export const PRIVATE_HOST = CONFIGS.api.privateHost;

async function getAccessToken(): Promise<string> {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
}

export async function getUser(userId: string): Promise<AxiosResponse<Username>> {
  return axios.get<Username>(PUBLIC_HOST + `/user/${userId}`);
}

export async function createUser(
  userId: string,
  username: string
  ): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/user/${userId}`,
    {
      "username": username
    },
    {
      headers: {
          Authorization: await getAccessToken()
        }
    }
  );
}

export async function updateUser(
  userId: string,
  username: string,
  thumbnail: string | undefined,
  contact: UserNotificationPreferences
  ): Promise<AxiosResponse<void>> {
  return axios.put<void>(PRIVATE_HOST + `/user/${userId}`,
    {
      "username": username,
      "thumbnail": thumbnail,
      "contact": contact
    },
    {
      headers: {
          Authorization: await getAccessToken()
        }
    }
  );
}

export async function getUserActivity(userId: string, activityId: string | undefined): Promise<AxiosResponse<ActivityList>> {
  return axios.get<ActivityList>(PUBLIC_HOST + `/user/${userId}/activity`, {
      params: {
        "activityId": activityId
      }
  });
}

export async function getUserNotifications(userId: string, notificationId: string | undefined): Promise<AxiosResponse<NotificationList>> {
  return axios.get<NotificationList>(PRIVATE_HOST + `/user/${userId}/notifications`, {
      params: {
        "notificationId": notificationId
      },
      headers: {
        Authorization: await getAccessToken()
      }
  });
}

export async function acknowledgeUserNotification(userId: string, notificationId: string): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/user/${userId}/notifications/${notificationId}/acknowledge`,
  {}, 
  {
    headers: {
        Authorization: await getAccessToken()
      }
  });
}

export async function postUnlinkUser(userId: string, providerName: string, providerValue: string): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/user/${userId}/unlink`,
  {
    "providerName": providerName,
    "providerValue": providerValue
  }, 
  {
    headers: {
        Authorization: await getAccessToken()
      }
  });
}

export async function getPresignedUrl(contentType: string): Promise<AxiosResponse<PostPresignedUrl>> {
  return axios.get<PostPresignedUrl>(PUBLIC_HOST + `/community/document`, {
      params: {
        contentType: contentType
      }
  });
}