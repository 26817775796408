<template>
    <div>
       <div class="rounded-image-container" :style="{ height: `${width}px`, width: `${width}px`, position: 'relative' }" @click="goToUser()">
          <img
             :src="thumbnailSource"
             onerror="this.onerror=null; this.src='/img/profile_not_found.png'"
             :style="{
             'max-height': `${width}px !important`,
             'max-width': `${width}px !important`,
             'min-height': `${width}px !important`,
             'min-width': `${width}px !important`,
             'object-fit': 'cover'
             }"
          >
       </div>
       <div style="position: relative;" v-if="notifications > 0" >
        <div class="notification-count notification-count-lg">
            {{notifications}}
        </div>
       </div>
    </div>
 </template>


<script lang="ts">
import { Username } from '@/models/User';
import { getUserThumbnail } from '@/utils/user';
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';

// eslint-disable-next-line
const bootstrap = require("bootstrap");

@Options({
    props: {
        user: {
            required: false,
            type: Object as PropType<Username>
        },
        displayStats: {
            required: false,
            default: false,
            type: Boolean
        },
        width: {
            required: false,
            default: 50,
            type: Number
        },
        notifications: {
            required: false,
            default: 0,
            type: Number
        }
    }
})
export default class ProfileThumbnail extends Vue {

    user!: Username;
    displayStats!: boolean;
    width!: number;
    notifications!: number;

    public mounted(): void {
        // Set bootstrap
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
    }

    public getThumbnailPath(thumbnail: string): string {
        return getUserThumbnail(thumbnail);
    }

    public goToUser(): void {
        this.$router.push({
            path: '/user/' + this.user?.userId,
         });
    }

    get thumbnailSource(): string {
        if (this.user?.userThumbnail) {
            return this.getThumbnailPath(this.user.userThumbnail);
        } else {
            return "/img/profile_not_found.png";
        }
    }

    get isMember(): boolean {
        if (this.user?.badges) {
            if (this.user?.badges.indexOf('member') !== -1) {
                return true;
            }
            return false;
        }
        return true
    }

    get isVerified(): boolean {
        if (this.user?.badges) {
            if (this.user?.badges.indexOf('verified') !== -1) {
                return true;
            }
            return false;
        }
        return true
    }

    get isAdministrator(): boolean {
        if (this.user?.badges) {
            if (this.user?.badges.indexOf('administrator') !== -1) {
                return true;
            }
            return false;
        }
        return true
    }

}
</script>


<style scoped>
.rounded-image-container {
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}
.rounded-image-container img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
}
.profile-badge {
    position: relative;
    float: right;
    bottom: 10px;
}
.icon-circle {
    width: 15px; 
    height: 15px; 
    border-radius: 50%;
    background-color: var(--bs-primary); 
    text-align: center;
    line-height: 11px; 
}

.icon-circle i {
    color: #ffffff;
    font-size: 7.5px;
}
.notification-count {
    --cnvs-top-cart-number-size: 1.125rem;
    font-size: 0.625rem;
    color: #FFF;
    width: var(--cnvs-top-cart-number-size);
    height: var(--cnvs-top-cart-number-size);
    line-height: var(--cnvs-top-cart-number-size);
    text-align: center;
    background-color: var(--cnvs-themecolor);
    opacity: 1;
    border-radius: 50%;
    position: absolute; /* Allows positioning relative to the parent */
    bottom: -5px; /* Adjust as necessary */
    right: -5px; /* Adjust as necessary */
}

/* Styles for greater than 992px */
@media (min-width: 993px) {
    .notification-count-lg {
        --cnvs-top-cart-number-size: 1.125rem;
        font-size: 0.625rem;
        color: #FFF;
        width: var(--cnvs-top-cart-number-size);
        height: var(--cnvs-top-cart-number-size);
        line-height: var(--cnvs-top-cart-number-size);
        text-align: center;
        background-color: var(--cnvs-themecolor);
        opacity: 1;
        border-radius: 50%;
        position: absolute !important; /* Allows positioning relative to the parent */
        bottom: -5px; /* Adjust as necessary */
        right: -5px; /* Adjust as necessary */
    }
}
</style>