/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CookieConsentPlugin from './plugins/cookieConsentPlugin';
import { FrameControl } from './models/FrameControl';

// Set config file and theme
import config from '../configs/configs.json';

// Load config filec
export const APP_CONFIGS: FrameControl = config;

// Create app
createApp(App).use(store).use(router).use(CookieConsentPlugin).mount('#app');

// Set favicon
function setFavicon(url: string): void {
    const link: HTMLLinkElement = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = url;
    document.head.appendChild(link);
}
setFavicon(config.assets.faviconImage);
