<template>
    <div class="overlay" v-if="showOverlay">
        <div class="overlay-content">
            <p>This site is under construction! Please enter the password to continue:</p>
            <div class="input-group input-group-lg mt-2 mb-4">
                <input
                    class="form-control border-0"
                    type="password"
                    placeholder="Enter Password..."
                    aria-label="Password"
                    v-model="password"
                >
                <button
                    class="button button-large m-0 bg-color text-light h-op-08"
                    type="submit"
                    value="submit"
                    style="border-top-right-radius: 0.5rem; border-bottom-right-radius: 0.5rem;"
                    @click="checkPassword"
                >Submit</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
  
@Options({

})
export default class BetaOverlay extends Vue {

    password = "";
    showOverlay = true;

    public mounted(): void {
        this.checkPasswordCookie();
    }

    checkPassword(): void {
      if (this.password === "letmein") {
        this.showOverlay = false;
        this.setPasswordCookie();
      } else {
        alert("Incorrect password. Please try again.");
        this.password = '';
      }
    }

    checkPasswordCookie(): void {
      const isPasswordEntered = localStorage.getItem('isPasswordEntered');
      if (isPasswordEntered === 'true') {
        this.showOverlay = false;
      }
    }
    
    setPasswordCookie(): void {
      localStorage.setItem('isPasswordEntered', 'true');
    }

}
</script>
  
<style>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}
</style>
  