import axios, { AxiosResponse } from "axios";
import { Auth } from "aws-amplify";
import config from '../../configs/configs.json';
import { FrameControl } from "@/models/FrameControl";
import { Vet, VetDetails, VetRating, VetReview } from "@/models/Vet";

export const CONFIGS: FrameControl = config;
export const PUBLIC_HOST = CONFIGS.api.publicHost;
export const PRIVATE_HOST = CONFIGS.api.privateHost;

async function getAccessToken(): Promise<string> {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
}

export async function postVetReview(vetId: string, review: VetRating): Promise<AxiosResponse<void>> {
  return axios.post<void>(PRIVATE_HOST + `/vet/${vetId}/review`, review,
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function deleteVetReview(vetId: string, reviewId: string): Promise<AxiosResponse<void>> {
  return axios.delete<void>(PRIVATE_HOST + `/vet/${vetId}/review/${reviewId}`, {
    headers: {
      Authorization: await getAccessToken()
    }
  });
}

export async function editVetReview(vetId: string, reviewId: string, review: VetRating): Promise<AxiosResponse<void>> {
  return axios.put<void>(PRIVATE_HOST + `/vet/${vetId}/review/${reviewId}`, review,
    {
      headers: {
        Authorization: await getAccessToken()
      }
    }
  );
}

export async function getVetReviews(vetId: string): Promise<AxiosResponse<VetReview[]>> {
  return axios.get<VetReview[]>(PUBLIC_HOST + `/vet/${vetId}/review`);
}

export async function getRecentVetReviews(): Promise<AxiosResponse<VetReview[]>> {
  return axios.get<VetReview[]>(PUBLIC_HOST + `/vet/review`);
}

export async function getVetDetails(vetId: string): Promise<AxiosResponse<VetDetails>> {
  return axios.get<VetDetails>(PUBLIC_HOST + `/vet/${vetId}`);
}

export async function searchVets(city: string): Promise<AxiosResponse<Vet[]>> {
  return axios.get<Vet[]>(PUBLIC_HOST + "/vet", {
    params: {
      "location": city
    }
  });
}
