<template>
   <div class="topic-post p-4 p-md-5 border-top border-width-5 border-color skeleton">
      <div class="row justify-content-between align-items-center mb-4">
         <div class="col-12">
            <h2 class="mb-0 fw-bold" style="word-wrap: break-word">
               <div class="skeleton-title"></div>
            </h2>
         </div>
      </div>
      <ul class="skeleton-para list-unstyled mb-0">
         <li></li>
         <li></li>
         <li></li>
      </ul>
      <div class="row g-0 justify-content-between align-items-center py-3 mb-4">
         <div class="col-12 d-flex">
            <div class="col-3 me-2">
               <div class="skeleton skeleton-2">
                  <div class="skeleton-avatar"></div>
               </div>
            </div>
            <div class="col-3 me-2">
               <div class="skeleton skeleton-2">
                  <div class="skeleton-avatar"></div>
               </div>
            </div>
            <div class="col-3 me-2">
               <div class="skeleton skeleton-2">
                  <div class="skeleton-avatar"></div>
               </div>
            </div>
         </div>
      </div>
   </div>
 </template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class LoadingPost extends Vue {

}
</script>

<style scoped>

</style>
    

