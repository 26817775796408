<template>
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class LoadingSpinner extends Vue {

}
</script>

<style scoped>
</style>
    

