<template>
    <div class="col-md-4 d-flex flex-column text-center align-items-center">
        <img
            :src="review.user.userThumbnail ? assetHost + '/' + review.user.userThumbnail : '/img/profile_not_found.png'"
            onerror="this.onerror=null; this.src='/img/profile_not_found.png'"
            class="rounded-circle mb-3 w-auto thumbnail-pic"
            width="64"
            height="64"
            >
        <a :href="'/vet/' + review.vetId">
            <h4 class="text-dark multi-line-ellipsis m-0">
                {{ review.vetDetails?.name }}
            </h4>
        </a>
        <h6 class="text-muted mb-0 fw-normal">
            {{ timeSince(Number(review.created)) }} ago
        </h6>
    </div>
    <div class="col-md-8 p-4 testi-content">
        <a :href="'/vet/' + review.vetId">
            <h4 class="text-dark multi-line-ellipsis m-0">
                {{ review.vetDetails?.name }}
            </h4>
        </a>
        <p class="m-0 pb-3 multi-line-ellipsis">
            {{ review.vetDetails?.city }}
        </p>
        <p>
            <StarRating :rating="Number(review.rating)" />
        </p>
        <p class="text-larger mb-0 multi-line-ellipsis">
            {{ getPreviewString(review.content) }}
        </p>
    </div>
</template>

<script lang="ts">
import { VetReview } from '@/models/Vet';
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { timeSince } from '@/utils/time';
import { getPreviewString } from "@/utils/html";
import StarRating from '../misc/StarRating.vue';
import { APP_CONFIGS } from '@/main';

@Options({
    props: {
        review: {
            required: true,
            type: Object as PropType<VetReview>
        },
    },
    components: {
        StarRating
    }
})
export default class VetReviewPreview extends Vue {

    review!: VetReview;

    public timeSince(epochMilliseconds: number | undefined): string {
        return timeSince(epochMilliseconds);
    }

    public getPreviewString(content: string): string {
        return getPreviewString(content);
    }

    get assetHost(): string {
        return APP_CONFIGS.api.assetHost;
    }
}
</script>


<style scoped>
.thumbnail-pic {
    max-width: 64px;
    min-width: 64px;
    max-height: 64px;
    min-height: 64px;
    object-fit: cover;
}
</style>
