<template>
	<div id="content">
		<div class="content-wrap py-0">
			<section id="slider" class="slider-element slider-parallax min-vh-100 include-header">
				<div class="slider-inner" :style="`background-image: url('${appConfigs.assets.homeBannerImage}'); background-color: #FFF;`">
					<div class="vertical-middle slider-element-fade">
						<div class="container text-start py-5">
							<div class="emphasis-title mb-4">
								<h1 class="text-uppercase fw-semibold" style="font-size: 56px; letter-spacing: 8px;">Your Home for<br>All Things Bunny</h1>
								<p class="lead mt-4 mb-0" style="max-width:700px; line-height: 1.7;">
									Welcome to our free-roam bunny community! Check out our articles, vet reviews, and engage with fellow bunny enthusiasts 
									in our community forums. 
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="section my-0 my-lg-5">
				<div class="container">
				<div class="row g-0 align-items-md-stretch align-items-lg-center">
					<div class="col-xl-7 col-md-6 img-overlap">
						<img :src="appConfigs.assets.homeAboutUsImage" alt="..." class="h-100" style="object-fit: cover;">
					</div>
					<div class="col-xl-5 col-md-6 bg-white">
						<div class="position-absolute start-0 top-0 w-100 h-100 bg-color bg-opacity-10"></div>
						<p class="color op-06 text-uppercase ls-4 small mb-3">Know More About Us</p>
						<h2 class="display-6">Your Home for All Things Bunny</h2>
						<p class="color">
							At Sincerely, Cinnabun, we're dedicated to promoting the welfare of pet rabbits. Check out our website for resources and 
							catch <a href="https://www.youtube.com/c/SincerelyCinnabun" target="_blank">Cinnabun Sundays</a> on my YouTube channel for tips and more. 
						</p>
						<a href="/about" class="button button-small border-color button-border  m-0 color h-bg-color h-text-light">About Us</a>
					</div>
				</div>
				</div>
			</div>
			<div class="section bg-color bg-opacity-10 my-0">
				<div class="container py-5">
				<div class="row justify-content-center mb-4">
					<div class="col-md-6 text-center">
						<p class="color text-uppercase ls-3 small mb-3">Blog</p>
						<h3 class="display-5 mb-5">Our Latest Posts</h3>
					</div>
				</div>
				<div class="row gutter-50 text-center">
					<div class="col-md-4 entry mb-0" v-for="post in posts.slice(0, 3)" :key=post.id>
						<article style="display: flex; flex-direction: column; height: 100%; position: relative;">
							<div style="flex: 1 0 auto;">
								<div class="entry-image mb-3">
									<a :href='"/post/" + post.attributes.title'>
										<img :src="post.attributes.thumbnail.data.attributes.formats.small.url" loading="lazy">
									</a>
								</div>
							</div>
							<div style="flex: 0 0 auto; padding-top: 20px;">
								<div class="entry-title title-sm">
									<h3>
										<a :href='"/post/" + post.attributes.title' class="color-underline stretched-link">
											{{ post.attributes.title }}
										</a>
									</h3>
									<div class="entry-categories">
										<a :href='"/post?category=" + post.attributes.category.data.attributes.name'>
											{{ post.attributes.category.data.attributes.name }}
										</a>
									</div>
									<div class="entry-categories" style="font-size: .85rem; color: #CCC; font-weight: 400;">
										{{ convertTimeToDate(post?.attributes.createdAt) }}
									</div>
								</div>
								<div class="entry-content mt-3">
									<a :href='"/post/" + post.attributes.title' class="more-link">Read More</a>
								</div>
							</div>
						</article>
					</div>
				</div>
				</div>
			</div>
			<div class="section bg-transparent my-0 py-0">
				<div class="container-fluid">
				<div class="clear"></div>
				<div class="row align-items-md-center">
					<div class="col-md-6 px-lg-0 min-vh-50 min-vh-lg-75 d-flex align-self-stretch" :style="`background: url(${appConfigs.assets.homeSectionImages[0]}) no-repeat center center; background-size: cover;`">
					</div>
					<div class="col-md-6 p-5 p-lg-6">
						<h3 class="fs-2 fw-medium mb-4 px-lg-5">Discover Rabbit Vets Near You</h3>
						<p class="px-lg-5 mb-5">
							Discover a vet directory tailored for rabbits in your area. We consolidate various sources to 
							guarantee top-notch specialized care for your beloved pets.  Leave a review to support your vet 
							and assist fellow bunny owners in making informed decisions about their pet's healthcare.
							<br>
							<br>
							<a href="/vets" class="read-more ls-1 text-uppercase border-bottom border-color small h-color h-op-08 ">Read More</a>
						</p>
					</div>
				</div>
				<div class="clear"></div>
				<div class="row flex-md-row-reverse align-items-md-center">
					<div class="col-md-6 px-lg-0 min-vh-50 min-vh-lg-75 d-flex align-self-stretch"  :style="`background: url(${appConfigs.assets.homeSectionImages[1]}) no-repeat center center; background-size: cover;`">
					</div>
					<div class="col-md-6 p-5 p-lg-6">
						<h3 class="fs-2 fw-medium mb-4 px-lg-5">Explore Our Community Forum</h3>
						<p class="px-lg-5 mb-5">
							Looking for reliable advice and support? Dive into our 
							community forum, where experienced rabbit owners and experts share insights, 
							tips, and stories to help you ensure the best care for your beloved bunnies. 
							Join the conversation, ask questions, and connect with fellow rabbit enthusiasts today.
							<br>
							<br>
							<a href="/community" class="read-more ls-1 text-uppercase border-bottom border-color small h-color h-op-08 ">Read More</a>
						</p>
					</div>
				</div>
				</div>
			</div>
			<div class="section bg-transparent my-0 py-0">
				<div class="container-fluid py-6">
				<div class="row justify-content-center mx-auto mw-md gx-5">
					<div class="col">
						<p class="h6 mb-0">
							Content and advice shared on this platform regarding represent the opinions of our community members 
							and should not be considered veterinary advice.  We are not licensed veterinarians. For professional guidance concerning your rabbit's health or care, 
							please consult a qualified veterinarian.  If you have inquiries, wish to rectify misinformation, or 
							seek clarification about our community, feel free to <a href="/about#contact">Contact Us</a>.
						</p>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
	</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Post } from '@/models/Cms';
import { loadNewContent } from '@/utils/cms';
import { convertTimeToDate } from '@/utils/time';
import { APP_CONFIGS } from '@/main';
import { FrameControl } from '@/models/FrameControl';

export default class ScbHome extends Vue {

    posts: Post[] = [];

    public async mounted(): Promise<void> {
        this.posts = await loadNewContent();
    }

    public convertTimeToDate(dateString: string): string {
		return convertTimeToDate(dateString);
    }

	get appConfigs(): FrameControl {
		return APP_CONFIGS;
	}

}
</script>

<style scoped>

</style>