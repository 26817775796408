// cookieConsentPlugin.ts
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import { CookieConsentConfig } from 'vanilla-cookieconsent';
import { App } from 'vue';
import { APP_CONFIGS } from '@/main';


const config: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'bottom left',
    },
    preferencesModal: {
      layout: 'box',
    },
  },

  onFirstConsent: () => {
    console.log('');
  },

  onConsent: () => {
    console.log('');
  },

  onChange: () => {
    console.log('');
  },

  onModalShow: () => {
    if (!APP_CONFIGS.general.enableCookies) {
      CookieConsent.hide();
    }
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
    ads: {},
  },

  language: {
    default: 'en',

    translations: {
      en: {
        consentModal: {
          title: "Cookie Settings",
          description:
            'By clicking “Accept", you agree to store cookies on your device and disclose \
            information in accordance with our Cookie Policy.',
          acceptAllBtn: 'Accept',
          acceptNecessaryBtn: 'Reject',
          showPreferencesBtn: 'Manage Preferences',
          footer: `
            <a href="/siteInfo/privacyPolicy">Privacy Policy</a>
            <a href="/siteInfo/cookiePolicy">Cookie Policy</a>
          `,
        },
        preferencesModal: {
          title: 'Cookie Preferences',
          acceptAllBtn: 'Accept All',
          acceptNecessaryBtn: 'Reject All',
          savePreferencesBtn: 'Save Preferences',
          sections: [
            {
              title: 'Cookie Usage',
              description:
                'When you visit any of our websites, it may store or retrieve information on your browser, \
                mostly in the form of cookies. This information might be about you, your preferences or your device and is \
                mostly used to make the site work as you expect it to. The information does not usually directly identify you, \
                but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose \
                not to allow some types of cookies. Click on the different category headings to find out more and manage your \
                preferences. Please note, blocking some types of cookies may impact your experience of the site and the \
                services we are able to offer.',
            },
            {
              title: 'Strictly Necessary Cookies',
              description:
                'These cookies are necessary for our website to function properly \
                and cannot be switched off in our systems. They are usually only set \
                in response to actions made by you which amount to a request for services, \
                such as setting your privacy preferences, logging in or filling in forms or \
                where they’re essential to provide you with a service you have requested. You cannot \
                opt-out of these cookies. You can set your browser to block or alert you about these \
                cookies, but if you do, some parts of the site will not then work. These cookies do \
                not store any personally identifiable information.',
              linkedCategory: 'necessary'
            },
            {
              title: 'Performance and Analytics Cookies',
              description:
                'These cookies allow us to count visits and traffic sources so we can measure and improve \
                the performance of our site. They help us to know which pages are the most and least popular \
                and see how visitors move around the site, which helps us optimize your experience. All \
                information these cookies collect is aggregated and therefore anonymous. If you do not \
                allow these cookies we will not be able to use your data in this way.',
              linkedCategory: 'analytics'
            },
            {
              title: 'Advertisement and Targeting Cookies',
              description:
                'These cookies are used to make advertising messages more relevant to you and may be set\
                 through our site by us or by our advertising partners. They may be used to build a profile\
                  of your interests and show you relevant advertising on our site or on other sites. They \
                  do not store directly personal information, but are based on uniquely identifying your \
                  browser and internet device.',
              linkedCategory: 'ads'
            },
            {
              title: 'More Information',
              description:
                'For any queries in relation to our policy on cookies and your choices, \
                 please <a class="cc__link" href="/about#contact">Contact Us</a>.',
            },
          ],
        },
      },
    },
  },
}

const CookieConsentPlugin = {
  install: (app: App) => {
    // Your plugin installation logic here
    app.config.globalProperties.$cookieConsent = CookieConsent;

    // You can initialize CookieConsent here if needed
    CookieConsent.run(config);
  },
};

export default CookieConsentPlugin;