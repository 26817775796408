import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScbHome = _resolveComponent("ScbHome")!
  const _component_ObbHome = _resolveComponent("ObbHome")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.appConfigs.general.name == 'Sincerely, Cinnabun')
      ? (_openBlock(), _createBlock(_component_ScbHome, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.appConfigs.general.name == 'The Outback Bowl')
      ? (_openBlock(), _createBlock(_component_ObbHome, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}