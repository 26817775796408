<template>
    <div class="container">
        <router-link :to="`/vet?city=${cityRef}`" v-if="cityRef">
            <button class="button m-0 bg-color text-light h-op-08 mt-5 mb-3">Back</button>
        </router-link>
        <a href="/vet" v-else>
            <button class="button m-0 bg-color text-light h-op-08 mt-5 mb-3">Back</button>
        </a>
        <br>
        <div class="row">
            <div class="col-md-6">
                <div v-if="!isApiCallInProgress">
                    <h2>
                    {{ vet?.name }}
                    </h2>
                    <div class="my-2">
                        <i class="fa-solid fa-location-dot"></i>
                        {{ vet?.address }}
                    </div>
                    <div class="my-2">
                        <i class="fa-solid fa-phone"></i>
                        {{ vet?.phone }}
                    </div>
                    <div class="my-2" v-if="vet?.website">
                        <i class="fa-solid fa-link"></i>
                        <a :href="vet?.website" target="_blank">
                            Website
                        </a>
                    </div>
                    <div class="my-2 mb-5">
                        <span v-if="vet?.reviews && vet.reviews.length > 0">
                            {{ Number(vet?.rating).toFixed(1) }} &nbsp;
                            <StarRating :rating=vet?.rating ></StarRating>
                            &nbsp;({{ (vet?.totalRatings) }} community <span v-if="vet.totalRatings === 1">review</span><span v-else>reviews</span>)
                            <br>
                        </span>
                        {{ Number(vet?.googleRating).toFixed(1) }} &nbsp;
                        <StarRating :rating=vet?.googleRating ></StarRating>
                        &nbsp;({{ (vet?.googleTotalRatings) }} <span v-if="vet?.googleTotalRatings === 1"><i class="fa-brands fa-google"></i> review</span><span v-else><i class="fa-brands fa-google"></i> reviews</span>)
                    </div>
                </div>
                <div v-else>
                    <LoadingVetDetails />
                </div>
            </div>
            <div class="col-md-6">
                <div class="google-map">
                    <iframe
                        :src="`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${vet?.address}`"
                        style="width: 100%; min-height: 300px;"
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </div>
        <button
            class="button button-border border-color mt-2 m-md-0 color h-bg-color h-text-light"
            @click="addReview"
            >
            Add Review
        </button>
        <hr>
        <div style="margin-bottom: 150px;">
            <div class="mb-3" v-for="review in sortedReviews" :key="review.vetId">
                <VetReviewPost :review="review" :vetId="postId" />
            </div>
            <div v-if="isApiCallInProgress">
                <LoadingVetUserReview class="mb-3" v-for="index in 3" :key="index" />
            </div>
            <div v-if="!isApiCallInProgress && reviews.length === 0" class="my-5 text-center">
                <div class="feature-box fbox-effect flex-column">
                    <div class="fbox-content">
                        <h3>No Reviews</h3>
                        <p>Be the first to share what you think</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section bg-transparent my-0 py-0">
        <div class="container-fluid py-6">
            <div class="row justify-content-center mx-auto mw-md gx-5">
                <div class="col">
                    <p class="h6 mb-0">
                        Our database combines multiple sources to generate a list of vets near your area.  Still don't see your vet? 
                        Need to correct some bad information? Have questions about our database?
                        <a href="/about#contact">Contact Us</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import { VetDetails, VetReview } from '@/models/Vet';
import { Options, Vue } from 'vue-class-component';
import store, { DataStore, VuexModuleNamespaces } from '@/store';
import { timeSince } from '@/utils/time';
import { deleteVetReview, getVetDetails } from '@/service/vet';
import StarRating from '@/components/misc/StarRating.vue';
import LoadingBox from '@/components/loading/LoadingBox.vue';
import LoadingVetDetails from '@/components/loading/LoadingVetDetails.vue';
import LoadingVetUserReview from '@/components/loading/LoadingVetUserReview.vue';
import ProfileThumbnail from '@/components/user/ProfileThumbnail.vue';
import { APP_CONFIGS } from '@/main';
import { flagContent } from '@/utils/flag';
import { showSignIn } from '@/utils/user';
import VetReviewPost from '@/components/vet/VetReviewPost.vue';

@Options({
    components: {
        StarRating,
        LoadingBox,
        LoadingVetDetails,
        LoadingVetUserReview,
        ProfileThumbnail,
        VetReviewPost
  }
})
export default class VetPost extends Vue {

    API_KEY = "AIzaSyAM9JAasDuzctn1p9wWAS_KBr1hXHV6h2Y"

    postId = ""
    cityRef = "";
    vet: VetDetails | null = null;

    selectedReviewId = "";

    isApiCallInProgress = false;
    isEditMode = false;

    async mounted(): Promise<void> {
        window.scrollTo({ top: 0, behavior: 'auto' });
        this.vet = null;
        this.postId = this.$route.params.postId as string;
        this.cityRef = this.$route.query.city as string;
        try {
            this.isApiCallInProgress = true;
            this.vet = await (await getVetDetails(this.postId)).data;

            // Reset post title
            const currentTitle = document.title;
            const newTitle = this.vet.name as string + currentTitle;
            document.title = newTitle;
        } catch(err) {
            console.log(err);
        } finally {
            this.isApiCallInProgress = false;
        }
    }

    public flagContent(content: string): void {
        flagContent(content);
    }

    public timeSince(epochMilliseconds: number): string {
        return timeSince(epochMilliseconds);
    }

    public isOwnReview(reviewUserId: string): boolean {
        return reviewUserId === this.userId;
    }

    public addReview(): void {
        if (!this.isLoggedIn) {
            showSignIn();
        } else {
            this.$router.push({
                path: `/vet/${this.vet?.googlePlaceId}/review`
            });
        }
    }

    public async deleteReview(): Promise<void> {
        try {
            await (await deleteVetReview(this.postId, this.selectedReviewId)).data;
            location.href = `/vet/${this.postId}`;
        } catch(err) {
            store.dispatch(`${VuexModuleNamespaces.App}/${DataStore.actions.emitApiError.name}`, err);
        }
    }

    public setSelectedReview(reviewId: string): void {
        this.selectedReviewId = reviewId;
    }

    public editReview(reviewId: string): void {
        this.$router.push({
            "path": `/vet/${this.postId}/review`,
            "query": {
                "edit": reviewId
            }
        });
    }

    get isLoggedIn(): boolean {
        return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getIsLoggedIn.name}`];
    }

    get reviewCount(): number {
        if (this.vet?.reviews) {
            return this.vet?.reviews.filter(review => !review.deleted).length;
        } else {
            return 0;
        }
    }

    get reviews(): VetReview[] {
        if (this.vet?.reviews) {
            return this.vet?.reviews.filter(review => !review.deleted);
        } else {
            return [];
        }
    }

    get sortedReviews(): VetReview[] {
        const compareFunction = (a: VetReview, b: VetReview) => {
            return b.created - a.created;
        };
        return this.reviews.slice().sort(compareFunction);
    }

    get assetHost(): string {
        return APP_CONFIGS.api.assetHost;
    }

    get userId(): string {
        return store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getUserId.name}`];
    }

}
</script>
  
<style scoped>

</style>