<template>
    <div class="Stars" :style="'--rating:' +  rating + ';'"></div>
</template>
  
<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        rating: Number
    }
})
export default class StarRating extends Vue {

    rating!: string
    
}
</script>
  
<style scoped lang="scss">
#textEditor {
  text-align: left;
}
.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  --star-size: 1.25rem;
  --star-color: #fff;
  --star-background: #fc0;

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
  
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
  